import { notification } from "antd"

export function sendSuccessNotification(
   message,
   title = "Success",
   options = {}
) {
   notification.success({
      placement: "topLeft",
      message: title,
      description: message,
      ...options,
   })
}

export function sendMessageNotification(
   message,
   title = "Error",
   options = {}
) {
   notification.open({
      placement: "topLeft",
      message: title,
      description: message,
      ...options,
      duration: 2,
   })
}

export function sendInfoNotification(message, title = "Success", options = {}) {
   notification.info({
      placement: "topLeft",
      message: title,
      description: message,
      ...options,
      duration: 2,
   })
}

export function sendErrorNotification(message, title = "Error", options = {}) {
   notification.error({
      placement: "topLeft",
      message: title,
      description: message,
      ...options,
   })
}

import {
   addDoc,
   collection,
   deleteDoc,
   doc,
   getDoc,
   getDocs,
   getFirestore,
   orderBy,
   query,
   setDoc,
   Timestamp,
} from "firebase/firestore"
import { rideStatus } from "../components/helpers/status"
import { getTimeText2 } from "../components/helpers/functions"
import {
   errorResponse,
   IServerResponse,
   okResponse,
} from "../components/helpers/serverResponse"
import writeXlsxFile from "write-excel-file"
import {
   Destination,
   DestinationStatus,
   DestinationType,
   Trip,
} from "../types/trip"
import dateFormat from "dateformat"
import { getAuth, getIdToken } from "firebase/auth"
import { dispatcherSettings } from "./settingsController"
import { getDispatcherId } from "./profileManagement"
import { convertToLocaleString } from "../components/helpers/dateHelper"
import { getFunctions, httpsCallable } from "firebase/functions"
import { getApp } from "firebase/app"
import app from "./firebase"

export async function cancelSubmittedRide(rideId) {
   try {
      const db = getFirestore()
      await deleteDoc(doc(db, "trips", rideId))
      return true
   } catch (e) {
      return false
   }
}

export interface AddLocationUpdate {
   location: string
   date?: any
   partialDropOff?: boolean
}

export async function editLocation(
   tripId: string,
   index: number,
   update: AddLocationUpdate
): Promise<boolean> {
   try {
      const db = getFirestore()

      var ride = await getDoc(doc(db, "trips", tripId))

      if (!ride.exists()) return false

      const tripData = ride.data()

      if (tripData.version === 1) {
         if (index + 1 > tripData.route.length) return false
         const trip: Trip = tripData as Trip

         const route = trip.route

         if (index === 0) {
            route[0].address = update.location
            route[0].arriveTime = Timestamp.fromMillis(update.date._d / 1)
            route[0].formattedArriveTime = convertToLocaleString(update.date._d)
         } else if (index === route.length - 1) {
            route[route.length - 1].address = update.location
         } else {
            route[index].address = update.location
            route[index].partialDropoff = update.partialDropOff ? true : false
            route[index].arriveTime = Timestamp.fromMillis(update.date._d / 1)
            route[index].formattedArriveTime = convertToLocaleString(
               update.date._d
            )
         }

         await setDoc(
            doc(db, "trips", tripId),
            {
               route: route,
            },
            { merge: true }
         )

         return true
      } else {
         var stops = ride.data().stopLocations
         var times = ride.data().times ?? []

         if (index === 0) {
            if (times.length === 0) {
               await setDoc(
                  doc(getFirestore(), "trips", tripId),
                  {
                     pickupLocation: update.location,
                  },
                  {
                     merge: true,
                  }
               )
               return true
            }

            return false
         } else if (index === stops.length + 1) {
            if (times.length < stops.length + 1) {
               await setDoc(
                  doc(getFirestore(), "trips", tripId),
                  {
                     dropOffLocation: update.location,
                  },
                  {
                     merge: true,
                  }
               )
               return true
            }

            return false
         }

         if (stops.length === 0 || index > stops.length) return false

         if (times.length >= index) return false

         stops[index - 1] = {
            ...stops[index - 1],
            ...update,
            partialDropOff: update.partialDropOff ?? false,
            date: update.date._d,
         }

         await setDoc(
            doc(getFirestore(), "trips", tripId),
            {
               stopLocations: stops,
            },
            {
               merge: true,
            }
         )

         return true
      }
   } catch (e) {
      return false
   }
}

export async function updateTripNote(
   tripId: string,
   note: string
): Promise<IServerResponse> {
   if (!note) return errorResponse("Note cannot be empty")

   try {
      const db = getFirestore()
      await setDoc(doc(db, "trips", tripId), { note: note }, { merge: true })
      return okResponse("Note updated")
   } catch (e) {
      return errorResponse(e.message)
   }
}

export async function removeStopLocation(rideId: string, index: number) {
   try {
      const db = getFirestore()

      var ride = await getDoc(doc(db, "trips", rideId))

      if (!ride.exists()) return false

      const tripData = ride.data()

      if (tripData.version === 1) {
         if (index + 1 > tripData.route.length || index < 0) return false

         const trip = tripData as Trip

         const isRoutePartial = trip.route[index].partialDropoff

         //remove index from route
         trip.route.splice(index, 1)

         const cs = trip.route[index - 1].status
         var ns: DestinationStatus

         if (
            cs === DestinationStatus.droppedOff ||
            cs === DestinationStatus.notStarted ||
            cs === DestinationStatus.inRoute
         ) {
            ns = DestinationStatus.notStarted
         } else {
            ns = DestinationStatus.inRoute
         }

         trip.route[index].status = ns

         await setDoc(
            doc(db, "trips", rideId),
            {
               route: trip.route,
               partialDroppedOff: isRoutePartial
                  ? false
                  : trip.partialDroppedOff ?? false,
            },
            { merge: true }
         )

         return true
      } else {
         var stops = ride.data().stopLocations
         var times = ride.data().times

         if (stops.length === 0 || index > stops.length) return false

         const tl = times?.length ?? 0

         if (tl >= index) return false
         stops.splice(index - 1, 1)

         const updates = {
            stopLocations: stops,
         }

         await setDoc(doc(getFirestore(), "trips", rideId), updates, {
            merge: true,
         })

         return true
      }
   } catch (e) {
      console.log(e)
      return false
   }
}

export async function addStopLocation(
   rideId,
   index,
   stopLocations: AddLocationUpdate[]
) {
   try {
      const db = getFirestore()

      var ride = await getDoc(doc(db, "trips", rideId))

      if (!ride.exists()) return false

      const tripData = ride.data()

      if (tripData.version === 1) {
         const trip = tripData as Trip

         const newStopLocations: Destination[] = stopLocations.map((t) => {
            const cs = trip.route[index].status
            var ns: DestinationStatus

            if (
               cs === DestinationStatus.notStarted ||
               cs === DestinationStatus.inRoute
            ) {
               ns = DestinationStatus.notStarted
            } else {
               ns = DestinationStatus.inRoute
            }

            const nd: Destination = {
               address: t.location,
               arriveTime: Timestamp.fromMillis(t.date._d / 1),
               partialDropoff: t.partialDropOff ? true : false,
               formattedArriveTime: convertToLocaleString(t.date._d),
               status: ns,
               arrived: false,
               type: DestinationType.stop,
            }

            return nd
         })

         //add new stop location to route index
         trip.route.splice(index + 1, 0, ...newStopLocations)

         trip.route[index + 2].status = DestinationStatus.notStarted

         await setDoc(
            doc(db, "trips", rideId),
            {
               route: trip.route,
            },
            { merge: true }
         )

         return true
      } else {
         var stops = ride.data().stopLocations
         var times = ride.data().times

         const tl = times?.length ?? 0
         if (tl > index) return false

         stopLocations = stopLocations.map((t) => {
            return {
               ...t,
               status: 1,
               date: t.date._d,
               partialDropOff: t.partialDropOff ?? false,
            }
         })

         for (var x = 0; x < stopLocations.length; x++) {
            stops.splice(index, 0, stopLocations[x])
            index++
         }

         const updates = {
            stopLocations: stops,
         }

         await setDoc(doc(getFirestore(), "trips", rideId), updates, {
            merge: true,
         })
         return true
      }
   } catch (e) {
      return false
   }
}

export async function startNewRide(trip: Trip) {
   try {
      const db = getFirestore()
      const doc1 = await addDoc(collection(db, "trips"), trip)

      return doc1.id
   } catch (e) {
      console.log(e)
      return false
   }
}

export async function exportAllRides() {
   const q = query(
      collection(getFirestore(), "trips"),
      orderBy("addedAt", "desc")
   )

   const trips = await getDocs(q)

   const headerRow = [
      {
         value: "Trip ID",
         fontWeight: "bold",
         width: 20,
      },
      {
         value: "Passenger",
         fontWeight: "bold",
         width: "auto",
      },
      {
         value: "Pickup Location",
         fontWeight: "bold",
      },
      {
         value: "Dropoff Location",
         fontWeight: "bold",
      },
      {
         value: "Pickup Time",
         fontWeight: "bold",
      },
      {
         value: "Dropoff Time",
         fontWeight: "bold",
      },
      {
         value: "Total Time (HH:MM)",
         fontWeight: "bold",
      },
      {
         value: "Wait Time (HH:MM)",
         fontWeight: "bold",
      },
      {
         value: "Distance Covered (miles)",
         fontWeight: "bold",
         width: "auto",
      },
   ]

   var dataRow = []

   trips.forEach((tx) => {
      const t = tx.data() as Trip
      const v: number = t.version ? t.version : 0

      dataRow.push([
         {
            type: String,
            value: t.tripId,
         },
         {
            type: String,
            value: t.passenger.name,
         },
         {
            type: String,
            //@ts-ignore
            value: v === 1 ? t.route[0].address : t.pickupLocation,
         },
         {
            type: String,
            value:
               v === 1
                  ? t.route[t.route.length - 1].address
                  : //@ts-ignore
                    t.dropOffLocation,
         },
         {
            type: String,
            value:
               v === 1
                  ? t.route[0].formattedArriveTime
                  : dateFormat(
                       //@ts-ignore
                       new Date(t.pickupDate.seconds * 1000),
                       "mm/dd/yyyy, hh:mm:ss TT",
                       false
                    ),
         },
         {
            type: String,
            value:
               t.status !== rideStatus.completed
                  ? "Not Completed"
                  : v === 1
                  ? getDropoffDateV1(t.route[t.route.length - 1])
                  : //@ts-ignore
                    getDateDropDate(t.time?.[t.stopLocations.length + 1]),
         },
         {
            type: String,
            value: getTimeText2(
               //@ts-ignore
               v === 1
                  ? t.totalTimeInSeconds
                  : //@ts-ignore
                    t.totalTimeInSec
            ),
         },
         {
            type: String,
            value: getTimeText2(
               //@ts-ignore
               v === 1 ? t.waitingTimeInSeconds : t.waitingTimeInSec
            ),
         },
         {
            type: String,
            value: (t.distanceCovered / 1609.34).toFixed(2) + "",
         },
      ])
   })

   const data = [headerRow, ...dataRow]

   await writeXlsxFile(data, {
      fileName: "trips.xlsx",
   })
}

function getDropoffDateV1(d) {
   return d.formattedEndTime
}

function getDateDropDate(t) {
   if (!t) return "Trip Not Completed"

   return dateFormat(
      new Date(t.seconds * 1000),
      "mm/dd/yyyy, hh:mm:ss TT",
      false
   )
}

export async function changePassenger(tripId, newPassengerId) {
   try {
      const trip = await getDoc(doc(getFirestore(), "trips", tripId))

      if (!trip.exists()) return errorResponse("Trip not exist.")

      var td = trip.data()

      if (td.status > rideStatus.accepted) {
         return errorResponse(
            "Trip can't be cancelled, driver already started the trip."
         )
      }

      const passenger = await getDoc(
         doc(getFirestore(), "passengers", newPassengerId)
      )

      if (!passenger.exists()) return errorResponse("Passenger not exist.")

      await setDoc(
         doc(getFirestore(), "trips", tripId),
         {
            passenger: {
               ...passenger.data(),
               id: newPassengerId,
            },
         },
         {
            merge: true,
         }
      )

      return okResponse("Passenger changed successfully!")
   } catch (e) {
      return errorResponse(e.message)
   }
}

export async function undoTrip(tripId: string): Promise<IServerResponse> {
   const token = await getIdToken(getAuth().currentUser)

   var endPoint = dispatcherSettings.tripHelperEndpoint

   //if / at the end then remove it
   if (endPoint.endsWith("/")) {
      endPoint = endPoint.substring(0, endPoint.length - 1)
   }
   // console.log(`${endPoint}/undo`, token, tripId)
   try {
      const response = await fetch(`${endPoint}/undo`, {
         method: "POST",
         body: JSON.stringify({
            tripId: tripId,
            uid: getDispatcherId(),
         }),
         headers: {
            "Content-Type": "application/json",
            "id-token": token,
         },
      })

      return (await response.json()) as IServerResponse
   } catch (e) {
      console.log(e)
      return errorResponse(e.message)
   }
}

export async function changeDriver(
   tripId,
   newDriverId
): Promise<IServerResponse> {
   try {
      const trip = await getDoc(doc(getFirestore(), "trips", tripId))

      if (!trip.exists()) return errorResponse("Trip not exist.")

      var td = trip.data()

      if (td.status > rideStatus.accepted) {
         return errorResponse(
            "Trip can't be cancelled, driver already started the trip."
         )
      }

      const response = await httpsCallable(
         getFunctions(app, "us-west4"),
         "changeDriver"
      )({
         tripId,
         newDriverId,
      })

      console.log(response)

      // await setDoc(
      //    doc(getFirestore(), "trips", tripId),
      //    {
      //       driverId: newDriverId,
      //    },
      //    {
      //       merge: true,
      //    }
      // )

      return okResponse("Driver changed successfully!")
   } catch (e) {
      console.log(e)
      return errorResponse(e.message)
   }
}

import { StopOutlined } from "@ant-design/icons"
import {
   Button,
   DatePicker,
   Form,
   Input,
   Menu,
   Modal,
   Switch,
   Tooltip,
} from "antd"
import React, { useEffect, useState } from "react"
import { FaExclamationCircle } from "react-icons/fa"
import moment from "moment"
import PlaceSuggestInput from "../../maps/PlaceSuggestInput"

export default function EditStopLocation({
   location,
   date,
   onConfirm,
   showPartialDropOff = true,
   showDatePicker = true,
   ...props
}) {
   const [show, setShow] = useState(false)
   const [form] = Form.useForm()

   useEffect(() => {
      form.setFieldsValue({
         location: location,
         date: date ? moment(date) : moment(new Date()),
         partialDropoff: false,
      })
   }, [])
   return (
      <>
         <Modal
            onOk={() => form.submit()}
            onCancel={() => setShow(false)}
            title='Edit'
            visible={show}>
            <Form
               onFinish={(a) => {
                  if (onConfirm) {
                     onConfirm(a)
                  }

                  setShow(false)
               }}
               form={form}>
               <div>
                  <div className='bg-blue-800 bg-opacity-5 flex flex-col p-5 rounded-md mb-5'>
                     <div
                        className={
                           "flex justify-between " +
                           (showPartialDropOff ? "" : "hidden")
                        }>
                        <div className='flex items-center'>
                           <Form.Item
                              name='partialDropOff'
                              valuePropName='checked'>
                              <Switch size='large' />
                           </Form.Item>
                           <p className='flex flex-row justify-center items-center m-0 ml-3 pb-6 text-base font-medium text-gray-600'>
                              <span>Partial Dropoff</span>
                              <Tooltip title='Drivers have to wait for some time and then get back to pickup the passenger'>
                                 <FaExclamationCircle className='ml-2' />
                              </Tooltip>
                           </p>
                        </div>
                     </div>

                     <Form.Item
                        className={showDatePicker ? "" : "hidden"}
                        label='Select Date & Time'
                        name='date'
                        rules={[
                           {
                              required: true,
                              message: "Select Time",
                           },
                        ]}>
                        <DatePicker
                           placeholder='Date & Time'
                           showTime
                           className='w-full -mb-3 rounded-md'
                           size='large'
                           format='MM-DD-YYYY HH:mm:ss'
                        />
                     </Form.Item>

                     <Form.Item
                        className='mb-0'
                        label={showDatePicker ? "Stop Location" : "Location"}
                        rules={[
                           {
                              required: true,
                              message: "Please input stop location",
                           },
                        ]}
                        name='location'>
                        <PlaceSuggestInput
                           size='lg'
                           className='rounded-md'
                           suffix={
                              <StopOutlined
                                 className='text-sm text-gray-400'
                                 style={{ color: "#505050" }}
                              />
                           }
                           rules={[
                              {
                                 required: true,
                                 message: "Please enter stop location",
                              },
                           ]}
                           placeholder='Stop Location'
                        />
                     </Form.Item>
                  </div>
               </div>
            </Form>
         </Modal>
         <Menu.Item {...props} onClick={() => setShow(true)}>
            Edit
         </Menu.Item>
      </>
   )
}

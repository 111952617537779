//@ts-nocheck
import React from "react"
import start from "../../../../assets/start.png"
import end from "../../../../assets/end.png"
import stop from "../../../../assets/stop.png"
import stopGreen from "../../../../assets/timeline/stop_green.png"
import endGreen from "../../../../assets/timeline/end_green.png"
import startGreen from "../../../../assets/timeline/start_green.png"
import { Dropdown, Menu, Popconfirm, Tag, Tooltip } from "antd"
import {
   DeleteFilled,
   EditFilled,
   EllipsisOutlined,
   PlusCircleFilled,
} from "@ant-design/icons"
import AddStopLocation from "./AddStopLocation"
import EditStopLocation from "./EditStopLocation"
import {
   getDestinationStatusColor,
   getDestinationStatusText,
   TripRoute,
} from "../../../../types/trip"
import {
   convertToLocaleString,
   getShortFormatedDate,
} from "../../../helpers/dateHelper"

interface TimelineItemProps {
   data: TripRoute
   first: boolean
   last: boolean
   showOptions: boolean
   showEdit: boolean
   showDelete: boolean
   showAdd: boolean
   onEdit: any
   onDelete: any
   onAdd: any
}

export default function TimelineItem({
   data,
   first,
   last,
   showOptions = true,
   showEdit = true,
   showDelete = true,
   showAdd = true,
   onEdit,
   onDelete,
   onAdd,
}: TimelineItemProps) {
   const menu = (
      <Menu>
         <EditStopLocation
            showDatePicker={!last}
            showPartialDropOff={!first && !last}
            onConfirm={onEdit}
            disabled={!showEdit}
            location={data.description}
            icon={<EditFilled />}
            date={data.arriveTime}
         />
         <Popconfirm onConfirm={onDelete} title='Are you sure?'>
            <Menu.Item disabled={!showDelete} icon={<DeleteFilled />}>
               Delete
            </Menu.Item>
         </Popconfirm>
         <AddStopLocation
            onConfirm={onAdd}
            disabled={!showAdd}
            icon={<PlusCircleFilled />}
         />
      </Menu>
   )
   var time = ""
   var arriveTime = ""

   if (typeof data.time === "string") {
      time = getShortFormatedDate(data.time)
   } else {
      if (data.time) {
         time = getShortFormatedDate(
            convertToLocaleString(new Date(data.time.seconds * 1000))
         )
      }
   }

   if (typeof data.arriveTime === "string") {
      arriveTime = getShortFormatedDate(data.arriveTime)
   } else {
      if (data.arriveTime) {
         arriveTime = getShortFormatedDate(
            convertToLocaleString(new Date(data.time.seconds * 1000))
         )
      }
   }

   return (
      <div className='flex flex-row '>
         <div className='flex flex-col items-center mr-1'>
            <img
               width={18}
               src={
                  !data.completed
                     ? first
                        ? start
                        : last
                        ? end
                        : stop
                     : first
                     ? startGreen
                     : last
                     ? endGreen
                     : stopGreen
               }
               alt='indication'
               className='mt-1'
            />
            <div className='flex flex-1 flex-row'>
               <Tooltip title={data.distance} className='w-4'>
                  <div className='flex flex-1 justify-center cursor-pointer'>
                     <div
                        className='flex  mt-1 mb-1 items-center justify-center'
                        style={{
                           borderColor: data.completed ? "#63AB42" : "#FFDE8A",
                           borderStyle: "dashed",
                           borderWidth: last ? "0px" : "1px",
                           width: "2px",
                           borderRadius: "15px",
                        }}></div>
                  </div>
               </Tooltip>
            </div>
         </div>
         <div className='flex flex-1 flex-col justify-start mb-3'>
            <div className='flex flex-row'>
               {arriveTime && (
                  <Tooltip title='Arrive Time'>
                     <p className='m-0' style={{ fontSize: "10px" }}>
                        {arriveTime}
                     </p>
                  </Tooltip>
               )}

               {arriveTime && time && (
                  <p
                     className='font-bold p-0 m-0 mr-2 ml-2'
                     style={{ fontSize: "10px" }}>
                     /
                  </p>
               )}

               {time && (
                  <>
                     <Tooltip title='Completion Time'>
                        <p className='m-0' style={{ fontSize: "10px" }}>
                           {time}
                        </p>
                     </Tooltip>
                  </>
               )}
            </div>

            <div className='flex justify-between items-center'>
               <div className='flex flex-row items-center'>
                  <p className='text-lg m-0 p-0  mr-2'>{data.title}</p>
                  <div>
                     {data.status && (
                        <Tag color={getDestinationStatusColor(data.status)}>
                           {getDestinationStatusText(data.status)}
                        </Tag>
                     )}
                  </div>
               </div>
               <Dropdown
                  className={showOptions ? "" : "hidden"}
                  placement='bottomRight'
                  overlay={menu}>
                  <EllipsisOutlined className='text-xl cursor-pointer' />
               </Dropdown>
            </div>
            <h3 className='text-base opacity-70'>{data.description}</h3>
         </div>
      </div>
   )
}

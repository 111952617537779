import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"
import React from "react"

export default function Loading({ size = "text-5xl" }) {
   return (
      <div className='h-full flex flex-1 items-center justify-center'>
         <Spin indicator={<LoadingOutlined className={size} spin />} />
      </div>
   )
}

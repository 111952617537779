//@ts-nocheck
import React, { useState, useEffect } from "react"
import { Trip } from "../../../../types/trip"
import { doc, getFirestore, onSnapshot } from "firebase/firestore"

interface TripHookProps {
   tripId: string
}

export default function useTrip(tripId) {
   const [trip, setTrip] = useState<Trip | null>(null)
   const [loading, setLoading] = useState(true)

   useEffect(() => {
      if (!tripId) return

      const unsubscribe = onSnapshot(
         doc(getFirestore(), "trips", tripId),
         (s) => {
            setLoading(false)
            if (!s.exists()) {
               return
            }

            setTrip({
               ...s.data(),
               id: s.id,
            } as Trip)
         }
      )

      return () => {
         unsubscribe()
      }
   }, [tripId])

   return {
      trip,
      loading,
   }
}

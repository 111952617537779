import {
   collection,
   addDoc,
   getDocs,
   setDoc,
   doc,
   getFirestore,
   deleteDoc,
   where,
   query,
} from "firebase/firestore"
import { getAuth } from "firebase/auth"

export async function addPassenger(passenger) {
   try {
      const db = getFirestore()

      const doc = await addDoc(collection(db, "passengers"), {
         ...passenger,
         dispatcherId: getAuth().currentUser.uid,
      })

      return doc.id
   } catch (e) {
      return false
   }
}

export async function updatePassenger(id, updates) {
   try {
      await setDoc(doc(getFirestore(), "passengers", id), updates, {
         merge: true,
      })

      return true
   } catch (e) {
      return false
   }
}

export async function getAllPassengers() {
   const db = getFirestore()
   const data = []
   const docs = await getDocs(query(collection(db, "passengers")))

   docs.forEach((x) => {
      data.push({
         id: x.id,
         ...x.data(),
      })
   })

   return data
}

export async function deletePassenger(id) {
   try {
      await deleteDoc(doc(getFirestore(), "passengers", id))
      return true
   } catch (e) {
      return false
   }
}

export interface IServerResponse {
   isSuccess: boolean
   message?: string
   data?: any
   error?: string[]
}

export const serverResponse = {
   isSuccess: false,
   message: "",
   data: null,
   error: [],
}

export function okResponse(message = "", data = null) {
   return {
      ...serverResponse,
      isSuccess: true,
      message: message,
      data: data,
   }
}

export function errorResponse(message = "", error = [], data = null) {
   return {
      ...serverResponse,
      isSuccess: false,
      message: message,
      error: error,
      data: data,
   }
}

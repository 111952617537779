import { doc, getFirestore } from "firebase/firestore"
import { useEffect, useState } from "react"
import { useDocument } from "react-firebase-hooks/firestore"
import { Driver, DriverLocationStatuses } from "../../../../types/driver"
import { Tag, Tooltip } from "antd"
import { getShortFormatedDate } from "../../../helpers/dateHelper"
import Loading from "../../../Loading"

export default function DriverLocationDetails({
   driverId,
   driver,
}: {
   driverId: string
   driver: Driver
}) {
   const [status, setStatus] = useState<DriverLocationStatuses | null>(null)
   const [driverStatusSnapshot, driverStatusLoading, error] = useDocument(
      doc(getFirestore(), "drivers-status", driverId ?? "")
   )
   useEffect(() => {
      if (driverStatusSnapshot && !driverStatusLoading) {
         setStatus(driverStatusSnapshot.data() as DriverLocationStatuses)
      }
   }, [driverStatusSnapshot, driverStatusLoading])

   if (driverStatusLoading) return <Loading />
   return (
      <>
         <Tag color={driver?.online ? "green" : "red"}>
            {driver?.online ? "Online" : "Offline"}
         </Tag>
         <Tooltip title='App: App must be opened to make the driver online'>
            <Tag color={status?.driverOnApp ? "blue" : "red"}>
               {status?.driverOnApp ? "App: On" : "App: Closed"}
            </Tag>
         </Tooltip>
         <Tooltip title='Location: Location service on device is required to track the driver location'>
            <Tag color={status?.locationEnabled ? "orange" : "red"}>
               {status?.locationEnabled
                  ? "Location: Enabled"
                  : "Location: Disabled"}
            </Tag>
         </Tooltip>
         <Tooltip title='Permission: Location permission is required to track the driver location'>
            <Tag color={status?.locationPermission ? "yellow" : "red"}>
               {status?.locationPermission
                  ? "Permission: Enabled"
                  : "Permission: Disabled"}
            </Tag>
         </Tooltip>
         <Tooltip title='Location Update: Date of last location update'>
            <Tag color='purple'>
               {getShortFormatedDate(
                  new Date((status?.lastLocationUpdate?.seconds ?? 0) * 1000)
               )}
            </Tag>
         </Tooltip>
      </>
   )
}

import React, { useEffect, useState } from "react"
import CustomMap from "./CustomMap"
import { DirectionsRenderer, Marker } from "react-google-maps"
import InfoBox from "react-google-maps/lib/components/addons/InfoBox"
import { Popover } from "antd"
import DriversMapPointer from "../rides/maps/DriversMapPointer"
import { rideStatus } from "../../helpers/status"

export default function RideDetailsMap({
   driver,
   loadingDriver,
   onArriveInfoUpdate,
   pickupLocation,
   locations,
   status = rideStatus.started,
   onInfo,
   onDistanceCalculated,
}) {
   const [showDriverToolTip, setShowDriverToolTip] = useState(false)
   const [towardsPickup, setTowardsPickup] = useState(null)
   const [towardsPickupCheck, setTowardsPickupCheck] = useState(false)
   const [directions, setDirections] = useState(null)
   const [pointers, setPointers] = useState([])

   useEffect(() => {
      if (directions) return
      if (!locations) return

      const calculateRoute = async () => {
         // eslint-disable-next-line no-undef
         const directionsService = new google.maps.DirectionsService()
         const results = await directionsService.route({
            origin: locations?.[0],
            destination: locations?.[locations?.length - 1],
            // eslint-disable-next-line no-undef
            travelMode: google.maps.TravelMode.DRIVING,
            waypoints: locations
               ?.slice(1, locations?.length - 1)
               .map((location) => ({
                  location: location,
                  stopover: true,
               })),
         })

         setDirections(results)

         onInfo?.(results.routes[0].legs.map((leg) => leg.distance.text))
         //sum of all legs
         onDistanceCalculated?.(
            results.routes[0].legs.reduce(
               (acc, leg) => acc + leg.distance.value,
               0
            )
         )
      }

      calculateRoute()
   }, [locations])

   useEffect(() => {
      if (!directions && !towardsPickup) return

      const getMarkers = (route) => {
         if (!route) return []
         const pointers = []

         const fr = route.routes[0]

         fr.legs.forEach((l, i) => {
            if (i === 0) {
               pointers.push({
                  lat: l.start_location.lat(),
                  lng: l.start_location.lng(),
               })
            }

            pointers.push({
               lat: l.end_location.lat(),
               lng: l.end_location.lng(),
            })
         })

         return pointers
      }

      const tpps = getMarkers(towardsPickup)
      const dpps = getMarkers(directions)

      if (tpps.length > 0) {
         setPointers([...tpps, ...dpps.slice(1)])
      } else setPointers(dpps)
   }, [directions, towardsPickup])

   useEffect(() => {
      if (!(driver?.location && pickupLocation)) return

      if (towardsPickupCheck) return

      const loadEstimation = async (origin, dest) => {
         try {
            // eslint-disable-next-line no-undef
            const directionsService = new google.maps.DirectionsService()
            const results = await directionsService.route({
               origin: origin,
               destination: dest,
               // eslint-disable-next-line no-undef
               travelMode: google.maps.TravelMode.DRIVING,
            })

            setTowardsPickup(results)

            onArriveInfoUpdate?.({
               time: results.routes[0].legs[0].duration.text,
               distance: results.routes[0].legs[0].distance.text,
            })
         } catch (e) {
            setTowardsPickup(null)
            onArriveInfoUpdate(null)
         }

         setTowardsPickupCheck(true)
      }

      loadEstimation(
         {
            lat: driver.location.lat,
            lng: driver.location.long,
         },
         pickupLocation
      )
   }, [pickupLocation, driver])

   if (loadingDriver) return <></>

   return (
      <CustomMap
         defaultZoom={13}
         defaultCenter={{
            lat: driver?.location?.lat,
            lng: driver?.location?.long,
         }}>
         {driver?.location && (
            <InfoBox
               options={{
                  closeBoxURL: "",
                  enableEventPropagation: true,
               }}
               position={{
                  lat: driver.location?.lat,
                  lng: driver.location?.long,
               }}>
               <Popover
                  lat={driver.location.lat}
                  key={"pointer-tool-" + driver.id}
                  lng={driver.location.long}
                  visible={showDriverToolTip}
                  content={<p className='m-0 text-base'>{driver.fullname}</p>}
                  className='flex flex-col items-center'>
                  <DriversMapPointer
                     name={driver.fullname}
                     image={driver.image}
                     onEnter={() => {
                        setShowDriverToolTip(true)
                     }}
                     onLeave={() => {
                        setShowDriverToolTip(false)
                     }}
                     key={"pointer-single-" + driver.id}
                     driverId={driver.id}
                     level={driver.verification.level}
                  />
               </Popover>
            </InfoBox>
         )}
         {directions && (
            <DirectionsRenderer
               options={{
                  polylineOptions: {
                     strokeColor: "#448EF7",
                     strokeOpacity: 0.8,
                     strokeWeight: 5,
                  },
                  suppressMarkers: true,
               }}
               directions={directions}
            />
         )}
         {towardsPickup && (
            <DirectionsRenderer
               options={{
                  polylineOptions: {
                     strokeColor: "#5DAC4A",
                     strokeOpacity: 0.8,
                     strokeWeight: 5,
                  },
                  suppressMarkers: true,
               }}
               directions={towardsPickup}
            />
         )}
         {pointers.map((p, i) => {
            return (
               <Marker
                  noRedraw={true}
                  key={`pointer-${i}-` + Math.random()}
                  position={p}
                  label={{
                     text: i + 1 + "",
                     color: "white",
                  }}
               />
            )
         })}
      </CustomMap>
   )
}

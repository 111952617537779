import {
   addDoc,
   collection,
   doc,
   getDoc,
   getDocs,
   getFirestore,
   query,
   updateDoc,
   where,
} from "firebase/firestore"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { getDispatcherId } from "./profileManagement"

export async function startNewChatWithDriver(uid) {
   const db = getFirestore()

   var driver = await getDoc(doc(db, "drivers", uid))

   if (driver.exists()) {
      var chats = await getDocs(
         query(
            collection(db, "chats"),
            where("members", "array-contains", getDispatcherId())
         )
      )
      var d =
         chats?.docs.map((c) => {
            var data = c.data()
            if (data.members.length < 1) return null

            return {
               userId: data.members.find((m) => m !== getDispatcherId()),
               chatId: c.id,
            }
         }) ?? []
      const isThere = d.some((c) => c.userId === uid)

      if (!isThere) {
         const chat = {
            members: [uid, getDispatcherId()],
            lastMessageTime: new Date().getUTCSeconds(),
         }

         await addDoc(collection(db, "chats"), chat)

         return true
      } else return false
   }

   return false
}

export async function addMessageToChat(chatId, message) {
   const db = getFirestore()
   await addDoc(collection(doc(db, `chats`, chatId), "messages"), {
      message,
      timestamp: Date.now(),
      sentBy: getDispatcherId(),
   })

   await updateDoc(doc(db, `chats`, chatId), {
      lastMessageTime: Date.now(),
   })
}

export async function getMessages(chatId) {
   const db = getFirestore()
   const docs = await getDocs(collection(doc(db, `chats`, chatId), "messages"))

   var data = []

   docs.forEach((x) => {
      data.push({
         id: x.id,
         ...x.data(),
      })
   })

   return data
}

export async function getDriverDetail(id) {
   const db = getFirestore()
   const d = await getDoc(doc(db, "drivers", id))
   const data = d.data()

   if (!d.exists()) {
      return null
   }

   return {
      name: data.fullname,
      online: data.online,
   }
}

export async function getDriverProfilePicture(id) {
   try {
      const storage = getStorage()
      const url = await getDownloadURL(
         ref(storage, "profile-pictures/" + id + ".png")
      )

      return url
   } catch (e) {
      return null
   }
}

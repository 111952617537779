import React, { useEffect, useState } from "react"
import {
   Col,
   PageHeader,
   Row,
   Breadcrumb,
   Avatar,
   Divider,
   Button,
   Space,
   Tag,
   Popconfirm,
   Tooltip,
   message,
   Menu,
   Dropdown,
} from "antd"
import { useHistory } from "react-router"
import Loading from "../../../Loading"
import { getRideStatusContent, rideStatus } from "../../../helpers/status"
import { getDriverProfilePicture } from "../../../../firebase/driverManagement"
import { convertHMS } from "../../../helpers/functions"
import {
   addStopLocation,
   cancelSubmittedRide,
   changeDriver,
   changePassenger,
   editLocation,
   removeStopLocation,
} from "../../../../firebase/rideMangement"
import { sendSuccessNotification } from "../../../helpers/notification"
import {
   MessageFilled,
   MoreOutlined,
   QuestionCircleOutlined,
} from "@ant-design/icons"
import { FaCar, FaPhone, FaUser } from "react-icons/fa"
import { getLevelIcon } from "../../../helpers/level"
import Timeline from "../timeline/Timeline"
import { useDocument } from "react-firebase-hooks/firestore"
import FeedbackForm from "../FeedbackForm"
import { updateFeedback } from "../../../../firebase/feedbackController"
import {
   convertToLocaleString,
   dateToString,
} from "../../../helpers/dateHelper"
import SelectPassenger from "../rideform/SelectPassenger"
import SelectDriverV2 from "../rideform/SelectDriverV2"
import RideDetailsMap from "../../maps/RideDetailsMap"
import { doc, getDoc, getFirestore } from "firebase/firestore"

export default function TripDetailsV1({ trip }) {
   const [driverProfilePictureUrl, setDriverProfilePictureUrl] = useState("")
   const [loadingCancelTrip, setLoadingCancelTrip] = useState(false)
   const [tripRoute, setTripRoute] = useState([])
   const history = useHistory()
   const [timelineLoading, setTimelineLoading] = useState(false)
   const [locations, setLocations] = useState()
   const [mapInfo, setMapInfo] = useState([])
   const [changingDriver, setChangingDriver] = useState(false)
   const [driverTimeToArrive, setDriverTimeToArrive] = useState(null)
   const [driverDistanceFromArrive, setDriverDistanceFromArrive] =
      useState(null)
   const [driverLastLocation, setDriverLastLocation] = useState({
      lat: 0,
      lng: 0,
   })
   const [driver, setDriver] = useState(null)
   const [driverSnapshot, loadingDriver, error] = useDocument(
      doc(getFirestore(), "drivers", trip?.driverId ?? "1")
   )
   const [changingPassenger, setChangingPassenger] = useState(false)

   useEffect(() => {
      if (trip) {
         const locations = [trip.pickupLocation]
         const route = [
            {
               title: "Pickup Location",
               description: trip.pickupLocation,
               time: trip.time?.[0],
               completed: trip.time?.[0] ? true : false,
               distance: mapInfo?.[0],
               showAdd: null,
            },
         ]

         trip.stopLocations.forEach((l, x) => {
            locations.push(l.location)
            route.push({
               title: "Stop Location",
               description: l.location,
               time: trip.time?.[x + 1],
               completed: trip.time?.[x + 1] ? true : false,
               distance: mapInfo?.[x + 1],
               showAdd: null,
            })
         })

         locations.push(trip.dropOffLocation)
         route.push({
            title: "Drop Off Location",
            description: trip.dropOffLocation,
            time: trip.time?.[route.length],
            completed: trip.time?.[route.length] ? true : false,
            showAdd: null,
         })

         setLocations(locations)
         setTripRoute(route)
      }
   }, [trip, mapInfo])

   useEffect(() => {
      if (driver) {
         getDriverProfilePicture(driver.id)
            .then((p) => setDriverProfilePictureUrl(p))
            .catch((e) => {
               console.log(e)
            })
      }
   }, [driver])

   useEffect(() => {
      if (driverSnapshot && driverSnapshot.exists()) {
         const d = {
            ...driverSnapshot.data(),
            id: driverSnapshot.id,
         }
         setDriver({
            ...driverSnapshot.data(),
            id: driverSnapshot.id,
         })
      }
   }, [driverSnapshot])

   useEffect(() => {
      if (!driver || !driver.location) return

      if (trip && trip.status === rideStatus.accepted && driver) {
         if (
            driverLastLocation.lat === driver.location.lat &&
            driverLastLocation.lng === driver.location.lng
         )
            return

         setDriverLastLocation({
            lat: driver.location.lat,
            lng: driver.location.long,
         })
      } else if (trip?.status !== rideStatus.accepted ?? false) {
         setDriverTimeToArrive(null)
      }
   }, [trip, driver])

   if (!trip) return <></>

   const rideChangeMenu = (
      <Menu>
         <SelectPassenger
            buttonText='Change Passenger'
            className='mr-2'
            as='menu'
            loading={changingPassenger}
            onSelect={async (p) => {
               setChangingPassenger(true)
               message.info("Changing passenger...")

               const response = await changePassenger(trip.id, p.id)

               if (response.isSuccess) {
                  message.success(response.message)
               } else message.error(response.message)

               setChangingPassenger(false)
            }}
            disabled={!(trip?.status === rideStatus.accepted)}
         />
         <SelectDriverV2
            as='menu'
            className='mr-2'
            text='Change Driver'
            loading={changingDriver}
            onSelect={async (d) => {
               setChangingDriver(true)
               message.info("Changing driver...")

               const response = await changeDriver(trip.id, d.id)

               if (response.isSuccess) {
                  message.success(response.message)
               } else message.error(response.message)

               setChangingDriver(false)
            }}
            disabled={!(trip?.status === rideStatus.accepted)}
         />
         <Popconfirm
            disabled={!(trip?.status <= rideStatus.accepted)}
            title='Are you sure?'
            onConfirm={async () => {
               setLoadingCancelTrip(true)
               const isSuccess = await cancelSubmittedRide(trip.id)

               if (isSuccess) {
                  sendSuccessNotification(
                     `[${trip.id}] Trip is cancelled (${trip.passenger.name})`
                  )

                  history.goBack()
               }

               setLoadingCancelTrip(false)
            }}
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}>
            <Tooltip
               arrowPointAtCenter
               placement='top'
               title={
                  trip?.status <= rideStatus.accepted
                     ? "Ride will removed"
                     : "Trip in progress"
               }>
               <Menu.Item
                  disabled={!(trip?.status <= rideStatus.accepted)}
                  loading={loadingCancelTrip}>
                  Cancel Trip
               </Menu.Item>
            </Tooltip>
         </Popconfirm>
      </Menu>
   )

   return (
      <div>
         <div className='flex justify-between'>
            <div className='flex flex-col flex-1'>
               <PageHeader
                  className='m-0 p-0'
                  title='Trip Details'
                  onBack={() => {
                     history.goBack()
                  }}
               />

               <div className='flex flex-row flex-1'>
                  <Breadcrumb className='ml-8 mb-5'>
                     <Breadcrumb.Item>
                        <a href='/dashboard/ride-details'>Tracking</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Trips Details</Breadcrumb.Item>
                  </Breadcrumb>
                  <div className='flex-1 justify-center flex'>
                     <Tooltip title='Trip Creation Date'>
                        <p className='p-0 ml-4 '>
                           {dateToString(new Date(trip.addedAt.seconds * 1000))}
                        </p>
                     </Tooltip>
                  </div>
               </div>
            </div>
            <div className='flex items-center mr-1 flex-row'>
               <Dropdown
                  // className={showOptions ? "" : "hidden"}
                  placement='bottomRight'
                  overlay={rideChangeMenu}>
                  <MoreOutlined className='text-2xl cursor-pointer' />
               </Dropdown>
            </div>
         </div>
         <Row className='pb-10'>
            <Col className='flex flex-col' span={16}>
               <div
                  className='h-full ride-details-map mb-8 rounded-md border overflow-hidden'
                  style={{ backgroundColor: "#F7F8FC" }}>
                  <RideDetailsMap
                     pickupLocation={
                        trip.status === rideStatus.accepted
                           ? trip?.pickupLocation
                           : null
                     }
                     onArriveInfoUpdate={(info) => {
                        setDriverDistanceFromArrive(info?.distance)
                        setDriverTimeToArrive(info?.time)
                     }}
                     onInfo={(info) => {
                        setMapInfo(info)
                     }}
                     locations={locations}
                     driver={
                        trip.status === rideStatus.completed ? null : driver
                     }
                     loadingDriver={
                        trip.status === rideStatus.completed ? false : !driver
                     }
                  />
               </div>

               <Row>
                  <Col className='pr-8' span={10}>
                     <div
                        className='p-8 rounded-md h-full'
                        style={{
                           background:
                              "linear-gradient(134.56deg, #FEDD2E 0%, #FEC42E 100%)",
                        }}>
                        <div className='flex flex-col h-full'>
                           <h2 className='text-white font-semibold text-xl mb-8'>
                              Trip Time
                           </h2>
                           {trip.status !== rideStatus.completed ? (
                              <>
                                 <div className='flex flex-1 text-white justify-center items-center'>
                                    <p className='text-lg'>
                                       Trip is not completed yet.
                                    </p>
                                 </div>
                              </>
                           ) : (
                              <>
                                 <div className='flex flex-col h-4/6'>
                                    <div className='flex justify-between flex-col'>
                                       <h5 className='text-base font-semibold'>
                                          Waiting Time:
                                       </h5>
                                       <p className='text-base'>
                                          {convertHMS(
                                             trip.totalWaitingTimeInSec
                                          )}
                                       </p>
                                    </div>
                                    <div className='flex flex-col justify-between'>
                                       <h5 className='text-base font-semibold mt-2'>
                                          Travel Time:
                                       </h5>
                                       <p className='text-base'>
                                          {convertHMS(
                                             trip.totalTimeInSec -
                                                trip.totalWaitingTimeInSec
                                          )}
                                       </p>
                                    </div>
                                    <div className='flex flex-col justify-between'>
                                       <h5 className='text-base font-semibold'>
                                          Total Time:
                                       </h5>
                                       <p className='text-base'>
                                          {convertHMS(trip.totalTimeInSec)}
                                       </p>
                                    </div>
                                 </div>
                              </>
                           )}
                        </div>
                     </div>
                  </Col>
                  <Col span={14}>
                     <div
                        className='flex flex-col p-8 rounded-md h-full'
                        style={{
                           background:
                              "linear-gradient(127.38deg, #2C6CF4 0%, #006AA6 100%)",
                        }}>
                        {loadingDriver ? (
                           <Loading />
                        ) : (
                           <>
                              <div className='flex flex-row items-center mb-2 justify-between'>
                                 <h2 className='font-semibold text-xl m-0  text-white'>
                                    Driver Information
                                 </h2>
                                 {driver ? (
                                    <Avatar
                                       src={driverProfilePictureUrl}
                                       className='w-16 h-16 flex items-center'
                                       size='large'
                                       style={{ backgroundColor: "#FFB700" }}>
                                       <p className='my-auto text-4xl font-semibold'>
                                          D
                                       </p>
                                    </Avatar>
                                 ) : (
                                    <></>
                                 )}
                              </div>

                              {trip.status === rideStatus.submited ? (
                                 <div className='flex flex-1 text-white justify-center items-center'>
                                    <p className='text-lg'>
                                       Trip is not accepted yet.
                                    </p>
                                 </div>
                              ) : driver ? (
                                 <>
                                    {driverTimeToArrive && (
                                       <div className='p-2 flex flex-1 bg-yellow-500 mt-2 rounded-lg'>
                                          <p className='m-0 p-0 text-white font-normal'>
                                             The distance from driver to the
                                             next point is{" "}
                                             <b className='font-bold'>
                                                {driverDistanceFromArrive}
                                             </b>
                                             , The driver will arrive in
                                             approximately{" "}
                                             <b className='font-bold'>
                                                {driverTimeToArrive}
                                             </b>
                                             .
                                          </p>
                                       </div>
                                    )}

                                    <div className='flex justify-between mt-5 flex-col flex-1'>
                                       <Row>
                                          <Col xs={24} md={12}>
                                             <div className='flex flex-row items-center flex-1 mr-2'>
                                                <FaUser className='text-white text-lg' />
                                                <p className='text-white text-lg m-0 ml-2'>
                                                   {driver.fullname}
                                                </p>
                                             </div>
                                          </Col>
                                          <Col xs={24} md={12}>
                                             <div className='flex flex-row flex-1 '>
                                                <div className='flex flex-row text-white   flex-1 items-center'>
                                                   {getLevelIcon(
                                                      driver.verification.level,
                                                      {
                                                         className:
                                                            "text-white text-lg",
                                                      }
                                                   )}
                                                </div>
                                             </div>
                                          </Col>
                                       </Row>
                                       <Row className='mt-2'>
                                          <Col xs={24} md={12}>
                                             <div className='flex flex-row items-center flex-1 mr-2'>
                                                <FaCar className='text-white text-lg' />
                                                <p className='text-white text-lg m-0 ml-2'>
                                                   {driver.vehicle.model}
                                                </p>
                                             </div>
                                          </Col>
                                          <Col xs={24} md={12}>
                                             <div className='flex flex-row items-center flex-1 mr-2'>
                                                <FaPhone className='text-white text-lg' />
                                                <p className='text-white text-lg m-0 ml-2'>
                                                   {driver.phone}
                                                </p>
                                             </div>
                                          </Col>
                                       </Row>
                                       {trip.driverFeedback && (
                                          <div className='flex flex-col mt-5'>
                                             <p className='font-semibold text-base p-0 m-0 text-yellow-500'>
                                                Private Feedback:{" "}
                                             </p>
                                             <p className='p-0 m-0 text-white'>
                                                {trip.driverFeedback}
                                             </p>
                                          </div>
                                       )}

                                       <div className='flex flex-1 mt-4 justify-end items-end '>
                                          <FeedbackForm
                                             size='small'
                                             className='mr-2'
                                             title='Provide Private Feedback for Driver'
                                             type='primary'
                                             defaultFeedback={
                                                trip.driverFeedback ?? ""
                                             }
                                             onFeedback={async (f) => {
                                                return await updateFeedback(
                                                   trip.id,
                                                   "driver",
                                                   f
                                                )
                                             }}
                                          />
                                          <Button
                                             size='small'
                                             type='primary'
                                             icon={<MessageFilled />}
                                             className='self-end'
                                             onClick={() => {
                                                history.push(
                                                   "/dashboard/messages/" +
                                                      driver.id
                                                )
                                             }}>
                                             Message
                                          </Button>
                                       </div>
                                    </div>
                                 </>
                              ) : (
                                 <></>
                              )}
                           </>
                        )}
                     </div>
                  </Col>
               </Row>
            </Col>
            <Col span={7} offset={1}>
               <Row className='h-full'>
                  <Col
                     className='p-4 rounded-md border'
                     span={24}
                     style={{
                        backgroundColor: "#F7F8FC",
                     }}>
                     <div
                        className='h-full'
                        style={{
                           overflow: "auto",
                        }}>
                        <Row>
                           <Col>
                              <div className='flex flex-col'>
                                 <div className='flex flex-row  justify-between'>
                                    <div className='flex flex-col'>
                                       <div
                                          style={{ color: "#6E7D8C" }}
                                          className='text-sm'>
                                          Trip ID:
                                       </div>
                                       <h1 className='text-base font-medium'>
                                          {trip.tripId}
                                       </h1>
                                    </div>
                                    <div className='flex flex-col'>
                                       <div
                                          style={{ color: "#6E7D8C" }}
                                          className='text-sm'>
                                          Distance Covered
                                       </div>
                                       <h1 className='text-base font-medium'>
                                          {trip?.distanceCovered
                                             ? `${(
                                                  trip.distanceCovered /
                                                  1609.344
                                               ).toFixed(2)} Miles`
                                             : "Unknown"}
                                       </h1>
                                    </div>
                                 </div>

                                 <div
                                    style={{ color: "#6E7D8C" }}
                                    className='text-sm'>
                                    Pickup Date
                                 </div>

                                 <h1 className='text-base font-medium'>
                                    {dateToString(
                                       trip.formatedDate?.pickup ??
                                          new Date(
                                             trip.pickupDate.seconds * 1000
                                          )
                                    )}
                                 </h1>
                              </div>
                           </Col>
                        </Row>

                        <Divider>Passenger Information</Divider>

                        <Row className='mb-2'>
                           <Col span={10}>
                              <div
                                 style={{ color: "#6E7D8C" }}
                                 className='text-sm'>
                                 Name:
                              </div>
                              <h1 className='text-base font-medium'>
                                 {trip.passenger.name}
                              </h1>
                           </Col>
                           <Col span={10} offset={4}>
                              <div
                                 style={{ color: "#6E7D8C" }}
                                 className='text-sm'>
                                 Number:
                              </div>
                              <h1 className='text-base font-medium'>
                                 {trip.passenger.phone}
                              </h1>
                           </Col>
                        </Row>

                        <Row>
                           <Col span={10}>
                              <div
                                 style={{ color: "#6E7D8C" }}
                                 className='text-sm'>
                                 Note:
                              </div>
                              <h1 className='text-base font-medium'>
                                 {trip.note}
                              </h1>
                           </Col>
                           <Col span={10} offset={4}>
                              <div
                                 style={{ color: "#6E7D8C" }}
                                 className='text-sm'>
                                 Status:
                              </div>
                              <h1 className='text-base font-medium'>
                                 <Space direction='vertical' size={"small"}>
                                    {getRideStatusContent(trip.status)}
                                    {trip.waiting ? (
                                       <Tag color={"orange"}>Waiting</Tag>
                                    ) : (
                                       <></>
                                    )}
                                 </Space>
                              </h1>
                           </Col>
                        </Row>
                        <div className='flex flex-col mt-2'>
                           <div className='flex justify-between items-center'>
                              <p className='p-0 m-0 text-base'>
                                 Private Feedback
                              </p>
                              <FeedbackForm
                                 size='small'
                                 className='mr-2'
                                 title='Provide Private Feedback for Passenger'
                                 type='ghost'
                                 defaultFeedback={trip.passengerFeedback ?? ""}
                                 onFeedback={async (f) => {
                                    return await updateFeedback(
                                       trip.id,
                                       "passenger",
                                       f
                                    )
                                 }}
                              />
                           </div>
                           <p className='p-0 m-0 mt-2'>
                              {trip.passengerFeedback}
                           </p>
                        </div>
                        <Divider className='mb-8 mt-10'>Locations</Divider>

                        <Timeline
                           loading={timelineLoading}
                           onEdit={async (i, d) => {
                              setTimelineLoading(true)
                              message.info("Editing...")
                              const response = await editLocation(trip.id, i, d)
                              if (response) {
                                 message.success("Edited successfully")
                              } else message.error("Something went wrong")
                              setTimelineLoading(false)
                           }}
                           onDelete={async (i) => {
                              setTimelineLoading(true)
                              message.info("Removing...")
                              const r = await removeStopLocation(trip.id, i)
                              if (r) {
                                 message.success("Stop location removed")
                              } else message.error("Something went wrong")
                              setTimelineLoading(false)
                           }}
                           onAdd={async (i, a) => {
                              setTimelineLoading(true)
                              message.info("Adding...")

                              const response = await addStopLocation(
                                 trip.id,
                                 i,
                                 a.stopLocations.map((s) => {
                                    return {
                                       ...s,
                                       formatedDate: convertToLocaleString(
                                          s.date._d
                                       ),
                                    }
                                 })
                              )
                              if (response)
                                 message.success("Stop location added")
                              else {
                                 console.log(response)
                                 message.error("Something went wrong")
                              }

                              setTimelineLoading(false)
                           }}
                           showOptions={trip.status <= rideStatus.arrived}
                           items={tripRoute}
                        />
                     </div>
                  </Col>
               </Row>
            </Col>
         </Row>
      </div>
   )
}

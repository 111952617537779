import { Col, message, Row, Space, Spin, Switch } from "antd"
import React, { useEffect, useState } from "react"
import {
   getSettings,
   updateSettings,
} from "../../../firebase/settingsController"
import { LoadingOutlined } from "@ant-design/icons"
import TimezoneSelect from "../rides/rideform/TimezoneSelect"
import { defualtSettings } from "../../helpers/defualtValues"
import { getTimeZones } from "@vvo/tzdb"

export default function SettingsView() {
   const [settings, setSettings] = useState({
      notificationsEnabled: false,
      timezone: {
         rawTimezone:
            "-06:00 Central Time - Chicago, Houston, San Antonio, Dallas",
         timeChange: "-6:00",
      },
   })
   const [loading, setLoading] = useState(true)

   useEffect(() => {
      getSettings().then((s) => {
         if (s) {
            setSettings({ ...settings, ...s })
         }
         setLoading(false)
      })
   }, [])
   return (
      <Spin spinning={loading} indicator={<LoadingOutlined />}>
         <div className='flex flex-1 flex-col space-x-2'>
            <div className='flex flex-row flex-1 justify-between items-center mb-8 p-6 pb-4 rounded-lg border bg-gradient-to-r from-blue-50 to-blue-200'>
               <h1
                  className='text-xl font-semibold '
                  style={{ color: "#272727" }}>
                  Application Settings
               </h1>

               <div className='flex flex-row items-start'>
                  <Switch
                     onChange={async () => {
                        setLoading(true)
                        message.info("Updating settings...")
                        const response = await updateSettings({
                           notificationsEnabled: !settings.notificationsEnabled,
                        })
                        if (response) {
                           message.success("Settings updated successfully")
                           setSettings({
                              ...settings,
                              notificationsEnabled:
                                 !settings.notificationsEnabled,
                           })
                        } else {
                           message.error("Error updating settings")
                        }

                        setLoading(false)
                     }}
                     checked={settings.notificationsEnabled}
                     className='mr-4'
                  />
                  <h1 className='m-0 p-0 text-base'>Notifications</h1>
               </div>
            </div>
            {/* <div className='flex flex-1 flex-col'>
               <Row clas>
                  <Col span={8}>
                     <p className='text-base'>Defualt Timezone: </p>
                  </Col>
                  <Col span={16}>
                     {settings?.timezone?.rawTimezone && (
                        <TimezoneSelect
                           onSelect={async (e) => {
                              setLoading(true)
                              const tzValue = getTimeZones().filter(
                                 (t) => t.rawFormat === e
                              )[0]?.name
                              message.info("Updating settings...")
                              const response = await updateSettings({
                                 timezone: {
                                    tz: tzValue,
                                    rawTimezone: e,
                                    timeChange: e.split(" ")[0],
                                 },
                              })

                              if (response) {
                                 message.success(
                                    "Settings updated successfully"
                                 )
                                 setSettings({
                                    ...settings,
                                    timezone: {
                                       tz: tzValue,
                                       rawTimezone: e,
                                       timeChange: e.split(" ")[0],
                                    },
                                 })
                              } else {
                                 message.error("Error updating settings")
                              }

                              setLoading(false)
                           }}
                           value={settings?.timezone?.rawTimezone}
                           defaultValue={defualtSettings.defaultTimezone}
                           className='w-full'
                        />
                     )}
                  </Col>
               </Row>
            </div> */}
         </div>
      </Spin>
   )
}

import { Tag } from "antd"
import React from "react"

export const rideStatus = {
   submited: 1,
   accepted: 2,
   started: 3,
   arrived: 4,
   completed: 5,
}

export function getRideStatusInText(rs) {
   if (rs === rideStatus.submited) return "Pending"
   if (rs === rideStatus.accepted) return "Accepted"
   if (rs === rideStatus.started) return "Started"
   if (rs === rideStatus.completed) return "Completed"
   if (rs === rideStatus.arrived) return "Arrived"
}

export function getRideStatusContent(status) {
   if (status === rideStatus.submited)
      return <Tag color={"blue"}>{getRideStatusInText(status)}</Tag>

   if (status === rideStatus.accepted)
      return <Tag color={"purple"}>{getRideStatusInText(status)}</Tag>

   if (status === rideStatus.started)
      return <Tag color={"pink"}>{getRideStatusInText(status)}</Tag>

   if (status === rideStatus.completed)
      return <Tag color={"green"}>{getRideStatusInText(status)}</Tag>

   if (status === rideStatus.arrived)
      return <Tag color={"cyan"}>{getRideStatusInText(status)}</Tag>

   return <Tag color={"red"}>{getRideStatusInText(status)}</Tag>
}

export const dispatcherStatus = {
   pending: 1,
   approved: 2,
   blocked: 3,
}

export const driverStatus = {
   pending: 1,
   approved: 2,
   blocked: 3,
}

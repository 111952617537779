import { Col, Divider, Image, Row, Form, Input, Button, Alert } from "antd"
import React, { useEffect, useState } from "react"
import { MailOutlined, LockOutlined } from "@ant-design/icons"
import { useHistory } from "react-router"
import {
   getAuth,
   onAuthStateChanged,
   signInWithEmailAndPassword,
} from "firebase/auth"
import backgroundCarImage from "../../assets/carbg.png"
import LRlogoColor from "../../assets/lrlogocolor.svg"
import Loading from "../Loading"
import { getDispatcherDetails } from "../../firebase/profileManagement"
import { dispatcherStatus } from "../helpers/status"
import { Link } from "react-router-dom"

export default function Login() {
   const history = useHistory()
   const [loading, setLoading] = useState(false)
   const [checking, setChecking] = useState(false)
   const [error, setError] = useState("")

   useEffect(() => {
      setChecking(true)
      onAuthStateChanged(getAuth(), async (user) => {
         if (user) {
            const response = await getDispatcherDetails()
            if (
               response &&
               response.verification &&
               response.verification.status === dispatcherStatus.approved
            ) {
               history.push("dashboard")
            } else setChecking(false)
         } else setChecking(false)
      })
   }, [])

   const onFinish = async (values) => {
      setLoading(true)
      try {
         const response = await signInWithEmailAndPassword(
            getAuth(),
            values.email,
            values.password
         )
         const details = await getDispatcherDetails(response.user.uid)

         if (!details.verification) {
            setError("You are not verified yet. Please contact admin.")
         } else if (details.verification.status === dispatcherStatus.approved)
            history.push("dashboard")
         else {
            setError(
               "Account Status: " +
                  ((details.verification?.status ?? false) ===
                  dispatcherStatus.blocked
                     ? "Blocked"
                     : "Pending")
            )
         }
      } catch (error) {
         if (error.code === "auth/wrong-password") {
            setError("Incorrect password")
         }
         if (error.code === "auth/user-not-found") {
            setError("User not found")
         }
      }
      setLoading(false)
   }

   if (checking)
      return (
         <div className='h-screen w-full'>
            <Loading />
         </div>
      )

   return (
      <Row
         className='h-screen flex flex-col md:flex-row items-center'
         style={{
            background: `url(${backgroundCarImage})`,
            backgroundSize: "cover",
         }}>
         <Col
            xs={22}
            md={14}
            className='flex flex-col justify-center items-center'>
            <Image src={LRlogoColor} preview={false} className='w-96' />

            <h2 className='mt-5 md:mt-8 text-3xl md:text-4xl text-white font-light'>
               Book Your Ride
            </h2>
         </Col>
         <Col xs={22} md={10} className='flex justify-center items-center'>
            <div className='flex flex-col bg-white p-8 w-80 rounded-2xl'>
               <h1 className='text-2xl font-bold mb-8'>
                  Login to LR Car <br /> Services
               </h1>
               <Form
                  name='normal_login'
                  className='login-form mt-8'
                  initialValues={{ remember: true }}
                  onFinish={onFinish}>
                  <Form.Item
                     name='email'
                     rules={[
                        {
                           required: true,
                           message: "Please input your Username!",
                        },
                     ]}>
                     <Input
                        prefix={<MailOutlined className='text-xl mr-2 mt-1' />}
                        placeholder='Email'
                        className='border-1 border-solid rounded-md h-12'
                        style={{ borderColor: "#272A30" }}
                     />
                  </Form.Item>
                  <Form.Item
                     name='password'
                     rules={[
                        {
                           required: true,
                           message: "Please input your Password!",
                        },
                     ]}>
                     <Input
                        prefix={<LockOutlined className='text-xl mr-2 mt-1' />}
                        type='password'
                        placeholder='Password'
                        className='border-1 border-solid rounded h-11'
                        style={{ borderColor: "#272A30" }}
                     />
                  </Form.Item>
                  {error !== null && error !== "" ? (
                     <Alert message={error} type='error' showIcon />
                  ) : (
                     <></>
                  )}
                  <Form.Item>
                     <Button
                        loading={loading}
                        htmlType='submit'
                        className='w-full text-black h-11 font-semibold mt-5'
                        style={{
                           backgroundColor: "#FEC42E",
                           borderColor: "#FEC42E",
                        }}>
                        LOGIN
                     </Button>
                  </Form.Item>
               </Form>

               <Link
                  className='text-base underline text-black text-center mb-5'
                  to={"/forgot-password"}>
                  Forgot Password ?
               </Link>
               <Divider className='mt-0' />
               <Button
                  className='w-full border-1 border-solid rounded h-11 font-semibold'
                  style={{ borderColor: "#505050" }}
                  onClick={() => history.push("/register")}>
                  REGISTER
               </Button>
            </div>
         </Col>
      </Row>
   )
}

import React, { useEffect, useState } from "react"
import ChatMessage from "./ChatMessage"
import { usePagination } from "use-pagination-firestore"

import {
   collection,
   doc,
   getFirestore,
   orderBy,
   query,
} from "firebase/firestore"
import { Button, Skeleton, Spin } from "antd"

export default function ChatBox({ chatId, onFirstLoad }) {
   const [limit, setLimit] = useState(20)
   const { items, isLoading, isStart, isEnd, getPrev, getNext } = usePagination(
      query(
         collection(doc(getFirestore(), `chats`, chatId), "messages"),
         orderBy("timestamp", "desc")
      ),
      {
         limit: limit,
      }
   )
   const [messages, setMessages] = useState([])

   const [first, setFirst] = useState(true)

   useEffect(() => {
      setMessages(items.reverse())
   }, [items])

   useEffect(() => {
      if (!isLoading) {
         setIsFirstLoading(false)
         if (onFirstLoad) onFirstLoad()
      }
   }, [isLoading])

   const [isFirstLoading, setIsFirstLoading] = useState(true)

   useEffect(() => {
      setIsFirstLoading(true)
      setFirst(true)
   }, [chatId])

   if (chatId === null)
      return (
         <div className='flex flex-1 justify-center items-center'>
            <h1 className='text-xl text-center'>Select chat</h1>
         </div>
      )

   return (
      <div className='flex flex-1 flex-col'>
         {isFirstLoading ? (
            <>
               {[...Array(20)].map((_, i) => (
                  <Skeleton.Input
                     loading={true}
                     className='ml-3 mt-2 mb-1'
                     active={true}
                  />
               ))}
            </>
         ) : (
            <>
               <Button
                  type='link'
                  disabled={isEnd}
                  onClick={() => {
                     setLimit(limit + 20)
                  }}
                  loading={isLoading}>
                  Load More
               </Button>
               {messages.map((m, x) => {
                  return (
                     <ChatMessage
                        onLoaded={() => {
                           if (first && x === limit - 1) {
                              setFirst(false)
                              document
                                 .getElementById("m-" + (x + 1))
                                 .scrollIntoView({ behavior: "smooth" })
                           }
                        }}
                        id={"m-" + (x + 1)}
                        key={"m-" + x + 1}
                        message={m}
                     />
                  )
               })}
            </>
         )}
      </div>
   )
}

import { CloseOutlined, PlusOutlined, StopOutlined } from "@ant-design/icons"
import {
   Button,
   Col,
   DatePicker,
   Form,
   Input,
   Menu,
   Modal,
   Switch,
   Tooltip,
} from "antd"
import React, { useEffect, useState } from "react"
import { FaExclamationCircle } from "react-icons/fa"
import moment from "moment"
import PlaceSuggestInput from "../../maps/PlaceSuggestInput"
export default function AddStopLocation({ onConfirm, ...props }) {
   const [show, setShow] = useState(false)
   const [form] = Form.useForm()
   useEffect(() => {
      form.setFieldsValue({
         stopLocations: [{ partialDropOff: false, date: moment(new Date()) }],
      })
   }, [])
   return (
      <>
         <Modal
            title='Add Stop Location'
            onCancel={() => setShow(false)}
            okText='Add'
            visible={show}
            onOk={() => {
               form.submit()
            }}>
            <Form
               onFinish={(d) => {
                  if (onConfirm) {
                     onConfirm(d)
                     setShow(false)
                  }
               }}
               form={form}>
               <Form.List name='stopLocations'>
                  {(fields, { add, remove }) => (
                     <>
                        <div
                           className='overflow-y-auto mb-5'
                           style={{
                              maxHeight: "500px",
                           }}>
                           {fields.map(({ key, name, ...restField }) => (
                              <div
                                 key={key}
                                 className='bg-blue-800 bg-opacity-5 flex flex-col p-5 rounded-md mb-5'>
                                 <div className='flex justify-between'>
                                    <div className='flex items-center'>
                                       <Form.Item
                                          {...restField}
                                          name={[name, "partialDropOff"]}
                                          valuePropName='checked'>
                                          <Switch
                                             size='large'
                                             defaultChecked={false}
                                          />
                                       </Form.Item>
                                       <p className='flex flex-row justify-center items-center m-0 ml-3 pb-6 text-base font-medium text-gray-600'>
                                          <span>Partial Dropoff</span>
                                          <Tooltip title='Drivers have to wait for some time and then get back to pickup the passenger'>
                                             <FaExclamationCircle className='ml-2' />
                                          </Tooltip>
                                       </p>
                                    </div>

                                    <div className='flex items-center pb-5'>
                                       <Button
                                          type='text'
                                          onClick={() => remove(name)}
                                          icon={<CloseOutlined />}
                                       />
                                    </div>
                                 </div>

                                 <Form.Item
                                    {...restField}
                                    label='Select Date & Time'
                                    rules={[
                                       {
                                          required: true,
                                          message: "Select Time",
                                       },
                                    ]}
                                    name={[name, "date"]}>
                                    <DatePicker
                                       placeholder='Date & Time'
                                       showTime
                                       className='w-full -mb-3 rounded-md'
                                       size='large'
                                       format='MM-DD-YYYY HH:mm:ss'
                                    />
                                 </Form.Item>

                                 <Form.Item
                                    {...restField}
                                    label='Stop Location'
                                    rules={[
                                       {
                                          required: true,
                                          message: "Please input stop location",
                                       },
                                    ]}
                                    name={[name, "location"]}>
                                    <PlaceSuggestInput
                                       size='large'
                                       className='rounded-md'
                                       suffix={
                                          <StopOutlined
                                             className='text-sm text-gray-400'
                                             style={{ color: "#505050" }}
                                          />
                                       }
                                       rules={[
                                          {
                                             required: true,
                                             message:
                                                "Please enter stop location",
                                          },
                                       ]}
                                       placeholder='Stop Location'
                                    />
                                 </Form.Item>
                              </div>
                           ))}

                           <Form.Item>
                              <Button
                                 block
                                 size='large'
                                 type='dashed'
                                 onClick={() => add()}
                                 icon={<PlusOutlined />}
                                 className='mt-2 flex items-center justify-center bg-transparent'>
                                 Add Stop Location
                              </Button>
                           </Form.Item>
                        </div>
                     </>
                  )}
               </Form.List>
            </Form>
         </Modal>
         <Menu.Item
            onClick={() => {
               setShow(true)
            }}
            {...props}>
            Add
         </Menu.Item>
      </>
   )
}

import React, { useEffect } from "react"
import { useState } from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { collection, doc, getDoc, getFirestore } from "firebase/firestore"

export default function DriverInformation({ driverId }) {
   const [loading, setLoading] = useState(true)
   const [driver, setDriver] = useState(null)
   useEffect(() => {
      const loadData = async () => {
         const driverInfo = await getDoc(
            doc(collection(getFirestore(), "drivers"), driverId)
         )
         setDriver(driverInfo.data())
         setLoading(false)
      }

      loadData()
   }, [])

   return (
      <>
         {loading ? (
            <LoadingOutlined />
         ) : (
            <p className='p-0 m-0'>{driver?.fullname}</p>
         )}
      </>
   )
}

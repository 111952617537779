import { Col, Divider, Image, Row, Form, Input, Button, Alert } from "antd"
import React, { useEffect, useState } from "react"
import { MailOutlined, LockOutlined, SendOutlined } from "@ant-design/icons"
import { useHistory } from "react-router"
import {
   getAuth,
   onAuthStateChanged,
   signInWithEmailAndPassword,
} from "firebase/auth"
import backgroundCarImage from "../../assets/carbg.png"
import LRlogoColor from "../../assets/lrlogocolor.svg"
import Loading from "../Loading"
import { getDispatcherDetails } from "../../firebase/profileManagement"
import { dispatcherStatus } from "../helpers/status"
import { Link } from "react-router-dom"
import { sendResetPasswordLink } from "../../firebase/driverManagement"

export default function ForgotPassword() {
   const [message, setMessage] = useState(null)
   const [loading, setLoading] = useState(false)
   const history = useHistory()
   return (
      <Row
         className='h-screen flex flex-col md:flex-row items-center'
         style={{
            background: `url(${backgroundCarImage})`,
            backgroundSize: "cover",
         }}>
         <Col
            xs={22}
            md={14}
            className='flex flex-col justify-center items-center'>
            <Image src={LRlogoColor} preview={false} className='w-96' />

            <h2 className='mt-5 md:mt-8 text-3xl md:text-4xl text-white font-light'>
               Book Your Ride
            </h2>
         </Col>
         <Col xs={22} md={10} className='flex justify-center items-center'>
            <div className='flex flex-col bg-white p-8 w-80 rounded-2xl'>
               <h1 className='text-2xl font-bold mb-8'>
                  Login to LR Car <br /> Services
               </h1>
               <Form
                  name='normal_login'
                  className='login-form mt-8'
                  initialValues={{ remember: true }}
                  onFinish={async (f) => {
                     setLoading(true)
                     const response = await sendResetPasswordLink(f.email)
                     setMessage(response)
                     setLoading(false)
                  }}>
                  <Form.Item
                     name='email'
                     rules={[
                        {
                           required: true,
                           message: "Enter your email address",
                        },
                     ]}>
                     <Input
                        prefix={<MailOutlined className='text-xl mr-2 mt-1' />}
                        placeholder='Enter your email address'
                        className='border-1 border-solid rounded-md h-12'
                        style={{ borderColor: "#272A30" }}
                     />
                  </Form.Item>
                  {message && (
                     <Alert
                        message={message.message}
                        type={message.isSuccess ? "success" : "error"}
                        showIcon
                     />
                  )}
                  <Form.Item>
                     <Button
                        loading={loading}
                        icon={<SendOutlined className='text-base' />}
                        htmlType='submit'
                        className='w-full text-black h-11 font-semibold mt-5'
                        style={{
                           backgroundColor: "#FEC42E",
                           borderColor: "#FEC42E",
                        }}>
                        Send Reset Link
                     </Button>
                  </Form.Item>
               </Form>

               <Divider className='mt-0' />
               <Button
                  className='w-full border-1 border-solid rounded h-11 font-semibold'
                  style={{ borderColor: "#505050" }}
                  onClick={() => history.push("/login")}>
                  Login
               </Button>
            </div>
         </Col>
      </Row>
   )
}

import React, { useEffect, useState } from "react"
import { Popover, Avatar, Badge, Button, List, Upload, message } from "antd"
import { getAuth } from "@firebase/auth"
import { FaCamera } from "react-icons/fa"

import {
   getProfilePicture,
   updateProfilePicture,
} from "../../firebase/profileManagement"

export default function LayoutHeader() {
   const [user, setUser] = useState(getAuth().currentUser)
   const [profilePictureUrl, setProfilePictureUrl] = useState("")

   useEffect(() => {
      const loadProfilePicture = async () => {
         const url = await getProfilePicture()
         setProfilePictureUrl(url)
      }

      loadProfilePicture()
   }, [])

   return (
      <div className='flex justify-between'>
         <div className='flex items-center relative'>
            <Avatar size={44} src={profilePictureUrl} className='bg-blue-400'>
               {(user?.displayName ?? user?.email)?.[0]}
            </Avatar>
            <Upload
               className='absolute left-2.5 top-1.5'
               onChange={async (f) => {
                  message.info("Uploading")
                  const url = await updateProfilePicture(
                     f.fileList[0].originFileObj
                  )
                  setProfilePictureUrl(url)
                  message.success("Uploaded")
               }}
               fileList={[]}
               type='link'>
               <Button
                  type='link'
                  className='opacity-0 hover:opacity-90'
                  icon={<FaCamera className='text-white text-2xl' />}
               />
            </Upload>
            <div className='flex flex-col ml-2'>
               <span className='leading-none text-base font-semibold mb-1'>
                  {user?.displayName ?? user?.email}
               </span>
               <span className='leading-none text-xs'>Dispatcher</span>
            </div>
         </div>
      </div>
   )
}

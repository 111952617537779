export const defualtSettings = {
   notificationsEnabled: true,
   defaultTimezone:
      "-06:00 Central Time - Chicago, Houston, San Antonio, Dallas",
   timeChange: "-6:00",
}

export const defualtTimezone = {
   tz: "America/Rio_Branco",
   rawTimezone: "-06:00 Central Time - Chicago, Houston, San Antonio, Dallas",
   timeChange: "-6:00",
}

import { Button } from "antd"
import React from "react"

export default function MoveToChat({ chatId, history }) {
   return (
      <Button
         type='primary'
         onClick={() => {
            history.push(`/dashboard/messages/${chatId}`)
         }}>
         Open Chat
      </Button>
   )
}

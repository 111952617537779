import {
   Button,
   Col,
   Input,
   Menu,
   Modal,
   Radio,
   Row,
   Spin,
   Switch,
   Table,
} from "antd"
import React, { useEffect, useState } from "react"
import { getAllPassengers } from "../../../../firebase/passengerManagement"
import { onReactTourActionPerformed } from "../../../helpers/reactTourHelper"
import ViewUpdatePassenger from "../../passengers/ViewUpdatePassenger"

export default function SelectPassenger({
   onSelect,
   buttonText = "Select Passenger",
   as = "button",
   ...props
}) {
   const [show, setShow] = useState(false)
   const [passengers, setPassengers] = useState([])
   const [loading, setLoading] = useState(false)

   const columns = [
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
         render: (text, record) => {
            return (
               <Radio checked={record.checked} value={record.id}>
                  {record.name}
               </Radio>
            )
         },
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
      },
      {
         title: "Phone",
         dataIndex: "phone",
         key: "phone",
      },
      {
         title: "Company Name",
         dataIndex: "company",
         key: "company",
      },
   ]

   useEffect(() => {
      const loadData = async () => {
         var data = await getAllPassengers()
         setPassengers(data)
      }

      loadData()
   }, [])

   const [searchText, setSearchText] = useState("")
   const [onlyVip, setOnlyVip] = useState(false)

   return (
      <>
         <Modal
            okButtonProps={{
               disabled: !passengers.some((p) => p.checked),
            }}
            okText='Select'
            visible={show}
            className='w-5/6'
            title='Select Passenger'
            onOk={() => {
               onReactTourActionPerformed(".select-passenger-table")
               if (onSelect) onSelect(passengers.find((p) => p.checked))
               setShow(false)
            }}
            onCancel={() => {
               setShow(false)
            }}>
            {loading ? (
               <div className='flex items-center justify-center'>
                  <Spin />
               </div>
            ) : (
               <>
                  <Row className='flex mb-2'>
                     <Col xs={24} md={12} lg={8}>
                        <Input
                           value={searchText}
                           onChange={(e) => setSearchText(e.target.value)}
                           placeholder='Search Passenger...'
                        />
                     </Col>
                     <Col className='flex justify-end' xs={24} md={12} lg={16}>
                        <div className='flex flex-row'>
                           <span className='mr-2'>Only VIP? </span>
                           <Switch checked={onlyVip} onChange={setOnlyVip} />
                        </div>
                     </Col>
                  </Row>
                  <Table
                     className='cursor-pointer select-passenger-table'
                     onRow={(record, rowIndex) => {
                        return {
                           onClick: (event) => {
                              setPassengers(
                                 passengers.map((p) => {
                                    if (p.id === record.id) {
                                       p.checked = true
                                    } else p.checked = false

                                    return p
                                 })
                              )
                           }, // mouse leave row
                           onDoubleClick: (e) => {
                              setPassengers(
                                 passengers.map((p) => {
                                    if (p.id === record.id) {
                                       p.checked = true
                                    } else p.checked = false

                                    return p
                                 })
                              )
                              onReactTourActionPerformed(
                                 ".select-passenger-table"
                              )
                              if (onSelect)
                                 onSelect(passengers.find((p) => p.checked))
                              setShow(false)
                           },
                        }
                     }}
                     dataSource={passengers.filter((p) => {
                        const f = p.name
                           .toLowerCase()
                           .includes(searchText.toLowerCase())

                        if (!f) return false

                        if (onlyVip) {
                           return p.vip
                        }

                        return true
                     })}
                     columns={columns}
                  />
                  <div className='flex justify-center items-center'>
                     <ViewUpdatePassenger
                        type='add'
                        addButtonText='Add New Passenger'
                        size='medium'
                        onAdd={(p) => {
                           setPassengers([...passengers, p])
                        }}
                     />
                  </div>
               </>
            )}
         </Modal>
         {as === "button" ? (
            <Button
               id='select-passenger-button'
               {...props}
               onClick={() => {
                  onReactTourActionPerformed("#select-passenger-button")
                  setShow(true)
               }}>
               {buttonText}
            </Button>
         ) : (
            <Menu.Item
               {...props}
               onClick={() => {
                  setShow(true)
               }}>
               {buttonText}
            </Menu.Item>
         )}
      </>
   )
}

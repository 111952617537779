import { Button, Checkbox, Input, Menu, Modal, Radio, Tooltip } from "antd"
import { collection, getFirestore, query, where } from "firebase/firestore"
import React, { useEffect } from "react"
import { useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { driverStatus } from "../../../helpers/status"
import Loading from "../../../Loading"
import { SearchOutlined } from "@ant-design/icons"
import Driver from "../Driver"
import DriversOnMap from "../DriversOnMap"
import PlaceSuggestInput from "../../maps/PlaceSuggestInput"
import {
   calculateDirectionRoute,
   calculateDistanceBetweenTwoPoints,
} from "../../../helpers/locationHelper"
import { MdGpsFixed } from "react-icons/md"
import { Marker } from "react-google-maps"
import { HiOutlineEmojiSad } from "react-icons/hi"
var randomColor = require("randomcolor")

export default function SelectDriverV2({
   text = "Select Driver",
   as = "button",
   onSelect,
   className,
   ...props
}) {
   const [drivers, setDrivers] = useState([])
   const [pickupLocation, setPickupLocation] = useState(null)
   const [filters, setFilters] = useState({
      online: true,
      level: 0,
      name: "",
   })
   const [showModal, setShowModal] = useState(false)
   const [distances, setDistances] = useState([])
   const [directions, setDirections] = useState([])
   const [filteredDrivers, setFilteredDrivers] = useState([])
   const [driversListen, loading, error, snapshot] = useCollectionData(
      query(
         collection(getFirestore(), "drivers"),
         where("verification.status", "==", driverStatus.approved)
      )
   )

   useEffect(() => {
      if (pickupLocation) {
         //select top 10 nearest drivers on pickup location
         const distances = filteredDrivers
            .map((d) => {
               if (!d.location) return null
               return {
                  ...d,
                  distance: calculateDistanceBetweenTwoPoints(
                     pickupLocation.location.lat,
                     pickupLocation.location.lng,
                     d.location.lat,
                     d.location.long
                  ),
               }
            })
            .filter((d) => d !== null)

         //top 10 distances

         const top10 = distances
            .sort((a, b) => a.distance - b.distance)
            .slice(0, 10)

         //calculate route for top 10 drivers

         const routes = top10.map(async (d) => {
            const results = await calculateDirectionRoute(
               pickupLocation.location,
               {
                  lat: d.location.lat,
                  lng: d.location.long,
               },
               true
            )
            return {
               route: results,
               driverId: d.id,
            }
         })

         Promise.all(routes).then((r) => {
            const colors = randomColor({
               count: 10,
               hue: "blue",
               luminosity: "dark",
            })
            navigator.clipboard.writeText(JSON.stringify(r[0]))
            setDirections(
               r.map((r, i) => ({
                  ...r,
                  color: colors[i],
               }))
            )

            setDistances(
               r.map((dir) => {
                  return {
                     distance: dir.route.routes?.[0]?.legs[0]?.distance?.text,
                     time: dir.route.routes?.[0]?.legs[0]?.duration?.text,
                     driverId: dir.driverId,
                     distanceValue:
                        dir.route.routes?.[0]?.legs[0]?.distance?.value,
                     timeValue: dir.route.routes?.[0]?.legs[0]?.duration?.value,
                  }
               })
            )
         })
      }
   }, [pickupLocation])

   useEffect(() => {
      if (snapshot) {
         setDrivers(
            snapshot.docs.map((d) => {
               var ed = drivers.find((e) => e.id === d.id)

               if (ed) {
                  return {
                     id: d.id,
                     ...d.data(),
                     checked: ed.checked,
                  }
               }

               return {
                  id: d.id,
                  ...d.data(),
               }
            })
         )
      }
   }, [snapshot])

   useEffect(() => {
      var fd = drivers
         .filter((d) => {
            if (filters.online && !d.online) return false

            const r = d.fullname
               .toLowerCase()
               .includes(filters.name.toLowerCase())

            if (!r) return false

            if (filters.level === 0) return true

            return filters.level === d.verification.level
         })
         .map((d) => {
            var distance = distances.find(
               (distance) => distance.driverId === d.id
            )

            if (!distance) {
               distance = {
                  distance: null,
                  time: null,
                  distanceValue: Number.POSITIVE_INFINITY,
                  timeValue: Number.POSITIVE_INFINITY,
               }
            }

            return {
               ...d,
               distance,
            }
         })
         .sort((a, b) => a.distance.distanceValue - b.distance.distanceValue)

      setFilteredDrivers(fd)
   }, [drivers, filters, distances])

   return (
      <>
         <Modal
            title='Select Driver'
            bodyStyle={{
               margin: 0,
               padding: 0,
               overflow: "hidden",
               height: "500px",
            }}
            footer={null}
            className='select-driver-model'
            onCancel={() => setShowModal(false)}
            visible={showModal}>
            <div className='flex h-full w-full'>
               {loading ? (
                  <Loading />
               ) : (
                  <>
                     <div className='w-3/5 p-3'>
                        <div className='flex flex-col h-full'>
                           <PlaceSuggestInput
                              prefix={
                                 <MdGpsFixed
                                    className='mr-1 text-md'
                                    style={{ color: "#505050" }}
                                 />
                              }
                              size='sm'
                              className='mb-2'
                              onLocationChange={(p) => {
                                 setPickupLocation(p)
                              }}
                              placeholder='Enter Pickup Location'
                           />
                           <div className='flex-1 rounded-md overflow-hidden'>
                              <DriversOnMap
                                 showMapOnNoDriver
                                 mapClassName='h-full'
                                 drivers={filteredDrivers}
                                 directShow
                                 directions={directions}
                                 customItems={
                                    pickupLocation
                                       ? [
                                            <Marker
                                               title='Pickup Location'
                                               position={
                                                  pickupLocation.location
                                               }
                                            />,
                                         ]
                                       : []
                                 }
                              />
                           </div>
                        </div>
                     </div>
                     <div className='w-2/5 flex flex-col p-3'>
                        <Input
                           placeholder='Search Driver'
                           size='small'
                           value={filters.name}
                           onChange={(e) => {
                              setFilters({ ...filters, name: e.target.value })
                           }}
                           prefix={<SearchOutlined />}
                        />

                        <div className='flex mt-2 justify-between flex-row items-center'>
                           <Radio.Group
                              size='small'
                              className='text-center mr-2'
                              onChange={(e) => {
                                 setFilters({
                                    ...filters,
                                    level: parseInt(e.target.value),
                                 })
                              }}
                              defaultValue={filters.level + ""}>
                              <Radio.Button value='0'>All</Radio.Button>
                              <Radio.Button value='3'>Platinum</Radio.Button>
                              <Radio.Button value='2'>Gold</Radio.Button>
                              <Radio.Button value='1'>Silver</Radio.Button>
                           </Radio.Group>
                           <Checkbox
                              className='text-center'
                              checked={filters.online}
                              onChange={(v) => {
                                 setFilters({
                                    ...filters,
                                    online: v.target.checked,
                                 })
                              }}>
                              Online?
                           </Checkbox>
                        </div>

                        <div
                           className={
                              "flex flex-col mt-2 overflow-auto " +
                              (filteredDrivers.length === 0
                                 ? "border flex-1 rounded-md"
                                 : "")
                           }>
                           {filteredDrivers.length === 0 && (
                              <div className='flex flex-1 flex-col items-center justify-center'>
                                 <HiOutlineEmojiSad className='text-5xl font-thin opacity-50' />
                                 <label className='opacity-50'>
                                    No Driver Available
                                 </label>
                              </div>
                           )}
                           {filteredDrivers.map((d, i) => {
                              return (
                                 <Driver
                                    onDriverSelect={(d) => {
                                       onSelect?.(d, pickupLocation)
                                       setShowModal(false)
                                    }}
                                    className='mb-2'
                                    key={d.id}
                                    showMap={false}
                                    size='small'
                                    driver={d}
                                    showSelect
                                    pickupDistance={d.distance.distance}
                                    pickupTime={d.distance.time}
                                 />
                              )
                           })}
                        </div>
                     </div>
                  </>
               )}
            </div>
         </Modal>
         {as === "menu" ? (
            <Menu.Item
               onClick={() => {
                  setShowModal(true)
               }}
               {...props}>
               {text}
            </Menu.Item>
         ) : as === "button" ? (
            <Button type='default' onClick={() => setShowModal(true)}>
               {text}
            </Button>
         ) : (
            <></>
         )}
      </>
   )
}

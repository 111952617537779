import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"
import React from "react"
import TimelineItem from "./TimelineItem"

export default function Timeline({
   items,
   showOptions,
   onDelete,
   onAdd,
   onEdit,
   loading = false,
}) {
   return (
      <div className='max-h-60'>
         <Spin
            spinning={loading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
            {items.map((d, i) => {
               return (
                  <TimelineItem
                     onAdd={(d) => {
                        onAdd(i, d)
                     }}
                     onEdit={(d) => {
                        onEdit(i, d)
                     }}
                     onDelete={() => {
                        onDelete(i)
                     }}
                     data={d}
                     showOptions={showOptions}
                     first={i === 0}
                     showDelete={
                        items[i].showDelete === null
                           ? i !== 0 && i !== items.length - 1 && !d.completed
                           : items[i].showDelete
                     }
                     showAdd={
                        items[i].showAdd === null
                           ? !items?.[i + 1]?.completed &&
                             i !== items.length - 1
                           : items[i].showAdd
                     }
                     status={d.status}
                     showEdit={
                        items[i].showEdit !== null
                           ? items[i].showEdit
                           : !d.completed
                     }
                     last={i === items.length - 1}
                  />
               )
            })}
         </Spin>
      </div>
   )
}

import React from "react"
import { BsTriangleFill } from "react-icons/bs"

export default function DriverLevel({ level, className = "", ...props }) {
   return (
      <div className={"flex flex-row items-center" + className} {...props}>
         <BsTriangleFill
            className={`level-${level}-solid-color m-0 mr-1 text-lg`}
         />
         <p className='m-0 text-md'>
            {level === 1 ? "Silver" : level === 2 ? "Gold" : "Platinum"}
         </p>
      </div>
   )
}

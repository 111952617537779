import {
   collection,
   doc,
   getFirestore,
   onSnapshot,
   query,
   where,
} from "firebase/firestore"
import { getDispatcherId } from "../../firebase/profileManagement"
import { sendSuccessNotification } from "./notification"
import { rideStatus } from "./status"

var listener = null
var trips = []
var settingListener = null

export function startSettingListner() {
   settingListener = onSnapshot(
      doc(getFirestore(), "dispatchers", getDispatcherId()),
      (snapshot) => {
         if (snapshot.exists) {
            const data = snapshot.data()
            if (data.settings && data.settings.notificationsEnabled) {
               startListeningRides()
            } else {
               stopListeningRides()
            }
         }
      }
   )
}
export function startListeningRides() {
   try {
      const q = query(
         collection(getFirestore(), "trips"),
         where("dispatcherId", "==", getDispatcherId())
      )
      listener = onSnapshot(q, (qs) => {
         qs.docChanges().forEach((change) => {
            if (change.type === "added") {
               trips.push({
                  id: change.doc.id,
                  ...change.doc.data(),
               })
            }
            if (change.type === "modified") {
               const updatedTrip = {
                  id: change.doc.id,
                  ...change.doc.data(),
               }

               var oldTrip = trips.find((trip) => trip.id === change.doc.id)

               trips = trips.map((trip) => {
                  if (trip.id === change.doc.id) {
                     return updatedTrip
                  }

                  return trip
               })

               if (
                  !oldTrip.show &&
                  updatedTrip.show &&
                  updatedTrip.status === rideStatus.submited
               ) {
                  sendSuccessNotification(
                     `[${updatedTrip.id}] Trip has been submitted (${updatedTrip.passenger.name})`
                  )
               } else if (
                  oldTrip.status === rideStatus.submited &&
                  updatedTrip.status === rideStatus.accepted
               ) {
                  sendSuccessNotification(
                     `[${updatedTrip.tripId}] Trip has been accepted please wait for the driver to arrive. (${updatedTrip.passenger.name})`
                  )
               } else if (
                  oldTrip.status === rideStatus.accepted &&
                  updatedTrip.status === rideStatus.started
               ) {
                  sendSuccessNotification(
                     `[${updatedTrip.tripId}] Driver has arrived at pickup location (${updatedTrip.passenger.name})`
                  )
               } else if (
                  oldTrip.status === rideStatus.started &&
                  updatedTrip.status === rideStatus.arrived
               ) {
                  sendSuccessNotification(
                     `[${updatedTrip.tripId}] Driver has arrived at destination (${updatedTrip.passenger.name})`
                  )
               } else if (
                  oldTrip.status === rideStatus.arrived &&
                  updatedTrip.status === rideStatus.completed
               ) {
                  sendSuccessNotification(
                     `[${updatedTrip.tripId}] Trip is marked as completed (${updatedTrip.passenger.name})`
                  )
               }

               if (oldTrip.waiting && !updatedTrip.waiting) {
                  sendSuccessNotification(
                     `[${updatedTrip.tripId}] Driver started trip again (${updatedTrip.passenger.name})`
                  )
               } else if (!oldTrip.waiting && updatedTrip.waiting) {
                  sendSuccessNotification(
                     `[${updatedTrip.tripId}] Driver is now waiting (${updatedTrip.passenger.name})`
                  )
               }
            }
            if (change.type === "removed") {
               trips = trips.filter((trip) => trip.id !== change.doc.id)
            }
         })
      })
   } catch (e) {
      console.log(e)
   }
}

export function stopListeningRides() {
   try {
      if (listener) listener()
   } catch (e) {}
}

export function stopListner() {
   if (settingListener) {
      settingListener()
   }
   stopListeningRides()
}

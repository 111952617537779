import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore"
import { getDispatcherId } from "./profileManagement"

export interface DispatcherSettings {
   defaultTimezone: string
   tripHelperEndpoint: string
}

export async function updateSettings(settings) {
   try {
      await setDoc(
         doc(getFirestore(), "dispatchers", getDispatcherId()),
         {
            settings: settings,
         },
         {
            merge: true,
         }
      )

      return true
   } catch (e) {
      return false
   }
}

export var dispatcherSettings: DispatcherSettings | null = null

export async function loadSettings() {
   try {
      dispatcherSettings = (await getSettings()) as DispatcherSettings
      console.log(dispatcherSettings)
   } catch (e) {
      dispatcherSettings = null
   }
}

export async function getSettings() {
   try {
      const snapshot = await getDoc(
         doc(getFirestore(), "settings", "dispatcher")
      )
      if (snapshot.exists()) return snapshot.data()

      return {
         defaultTimezone: "-6:00",
      }
   } catch (e) {
      return {
         defaultTimezone: "-6:00",
      }
   }
}

import {
   Button,
   Checkbox,
   Col,
   Input,
   Menu,
   Modal,
   Radio,
   Row,
   Space,
   Spin,
   Table,
   Tag,
} from "antd"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { getLevelIcon } from "../../../helpers/level"
import DriversOnMap from "../DriversOnMap"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { collection, getFirestore, query, where } from "firebase/firestore"
import { driverStatus } from "../../../helpers/status"
import { onReactTourActionPerformed } from "../../../helpers/reactTourHelper"

export default function SelectDriver({
   text = "Select Driver",
   onSelect,
   as = "button",
   ...props
}) {
   const [show, setShow] = useState(false)
   const [drivers, setDrivers] = useState([])
   const [showOnline, setShowOnline] = useState(false)
   const [searchText, setSearchText] = useState("")
   const history = useHistory()

   const columns = [
      {
         title: "Name",
         dataIndex: "fullname",
         key: "fullname",
         render: (text, record) => {
            return (
               <Radio checked={record.checked} value={record.id}>
                  {text}
               </Radio>
            )
         },
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
      },
      {
         title: "Phone",
         dataIndex: "phone",
         key: "phone",
      },
      {
         title: "Level",
         dataIndex: "level",
         key: "level",
         render: (text, record) => {
            return getLevelIcon(record.verification.level)
         },
      },
      {
         title: "Status",
         dataIndex: "status",
         key: "status",
         render: (text, record) => {
            return (
               <Tag color={record.online ? "green" : "red"}>
                  {record.online ? "Online" : "Offline"}
               </Tag>
            )
         },
      },
      {
         title: "Options",
         key: "A",
         render: (text, record) => {
            return (
               <Space>
                  <DriversOnMap buttonText='On Map' drivers={[record]} />
                  <Button
                     type='primary'
                     size='small'
                     loading={record.startingMessage}
                     onClick={async () => {
                        history.push("/dashboard/messages/" + record.id)
                     }}>
                     Message
                  </Button>
               </Space>
            )
         },
      },
   ]

   const [driversListen, loading, error, snapshot] = useCollectionData(
      query(
         collection(getFirestore(), "drivers"),
         where("verification.status", "==", driverStatus.approved)
      )
   )

   useEffect(() => {
      if (snapshot) {
         setDrivers(
            snapshot.docs.map((d) => {
               var ed = drivers.find((e) => e.id === d.id)

               if (ed) {
                  return {
                     id: d.id,
                     ...d.data(),
                     checked: ed.checked,
                  }
               }

               return {
                  id: d.id,
                  ...d.data(),
               }
            })
         )
      }
   }, [snapshot])

   const [level, setLevel] = useState(0)
   return (
      <>
         <Modal
            okButtonProps={{
               disabled: !drivers.some((p) => p.checked),
            }}
            okText='Select'
            visible={show}
            className='w-5/6'
            title='Select Driver'
            onOk={() => {
               onReactTourActionPerformed(".select-driver-table")
               if (onSelect) onSelect(drivers.find((p) => p.checked))
               setShow(false)
            }}
            onCancel={() => {
               setShow(false)
            }}>
            {loading ? (
               <div className='flex items-center justify-center'>
                  <Spin />
               </div>
            ) : (
               <>
                  <Row className=' flex flex-row justify-end mb-2 items-center'>
                     <Col className='sm:mb-2 lg:mb-0' xs={24} lg={12} span={12}>
                        <Input
                           onSearch={(t) => {}}
                           value={searchText}
                           onChange={(e) => setSearchText(e.target.value)}
                           className='sm:w-2/6 lg:w-3/6'
                           placeholder='Search Driver'
                        />
                     </Col>
                     <Col
                        lg={12}
                        xs={24}
                        span={12}
                        className='flex justify-end flex-row items-center'>
                        <Radio.Group
                           className='mr-2'
                           onChange={(e) => {
                              setLevel(parseInt(e.target.value))
                           }}
                           defaultValue={level + ""}
                           size='medium'>
                           <Radio.Button value='0'>All</Radio.Button>
                           <Radio.Button value='3'>Platinum</Radio.Button>
                           <Radio.Button value='2'>Gold</Radio.Button>
                           <Radio.Button value='1'>Silver</Radio.Button>
                        </Radio.Group>
                        <Checkbox
                           onChange={(e) => {
                              setShowOnline(e.target.checked)
                           }}>
                           Show Online
                        </Checkbox>
                     </Col>
                  </Row>
                  <div className='overflow-auto'>
                     <Table
                        onRow={(record, rowIndex) => {
                           return {
                              onClick: (event) => {
                                 setDrivers(
                                    drivers.map((p) => {
                                       if (p.id === record.id) {
                                          p.checked = true
                                       } else p.checked = false

                                       return p
                                    })
                                 )
                              }, // click row
                              onDoubleClick: (event) => {
                                 onReactTourActionPerformed(
                                    ".select-driver-table"
                                 )
                                 setDrivers(
                                    drivers.map((p) => {
                                       if (p.id === record.id) {
                                          p.checked = true
                                       } else p.checked = false

                                       return p
                                    })
                                 )

                                 if (onSelect)
                                    onSelect(drivers.find((p) => p.checked))
                                 setShow(false)
                              }, // double click row
                              onContextMenu: (event) => {}, // right button click row
                              onMouseEnter: (event) => {}, // mouse enter row
                              onMouseLeave: (event) => {}, // mouse leave row
                           }
                        }}
                        className='cursor-pointer select-driver-table'
                        dataSource={drivers.filter((d) => {
                           if (showOnline && !d.online) return false

                           const r = d.fullname
                              .toLowerCase()
                              .includes(searchText.toLowerCase())

                           if (!r) return false

                           if (level === 0) return true

                           return level === d.verification.level
                        })}
                        columns={columns}
                     />
                  </div>
               </>
            )}
         </Modal>
         {as === "button" ? (
            <Button
               {...props}
               id='select-driver-button'
               onClick={() => {
                  onReactTourActionPerformed("#select-driver-button")
                  setShow(true)
               }}>
               {text}
            </Button>
         ) : (
            <Menu.Item
               {...props}
               onClick={() => {
                  setShow(true)
               }}>
               {text}
            </Menu.Item>
         )}
      </>
   )
}

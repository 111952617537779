//@ts-nocheck
import React from "react"
import { dateToString } from "../../../helpers/dateHelper"

interface TimeRendererProps {
   date: Date | any
}

export default function TimeRenderer({ date }: TimeRendererProps) {
   return <div>{dateToString(date)}</div>
}

import React from "react"
import { GoogleMap, withGoogleMap } from "react-google-maps"
import { compose, withProps } from "recompose"

const CustomMap = compose(
   withProps({
      loadingElement: <div style={{ height: `100%`, width: "100%" }} />,
      containerElement: <div style={{ height: `100%`, width: "100%" }} />,
      mapElement: <div style={{ height: `100%`, width: "100%" }} />,
   }),
   withGoogleMap
)(
   ({
      children,
      defaultZoom = 8,
      defaultCenter = {
         lat: -34.397,
         lng: 150.644,
      },
      ...props
   }) => {
      return (
         <GoogleMap
            defaultZoom={defaultZoom}
            defaultCenter={defaultCenter}
            {...props}>
            {children}
         </GoogleMap>
      )
   }
)

export default CustomMap

export function getTimeText2(sec) {
   try {
      return new Date(sec * 1000).toISOString().substring(11, 19)
   } catch (e) {
      return "00:00"
   }
}
export function getTimeText(totalSeconds) {
   try {
      var delta = Math.abs(new Date(totalSeconds * 1000) - new Date()) / 1000
      var days = Math.floor(delta / 86400)
      delta -= days * 86400
      var hours = Math.floor(delta / 3600) % 24
      delta -= hours * 3600
      var minutes = Math.floor(delta / 60) % 60
      delta -= minutes * 60
      var seconds = delta % 60

      return (
         String(hours).padStart(2, "0") +
         ":" +
         String(minutes).padStart(2, "0") +
         ":" +
         String(parseInt(seconds)).padStart(2, "0")
      )
   } catch (e) {
      return "00:00:00"
   }
}

export function convertHMS(value) {
   const sec = parseInt(value, 10) // convert value to number if it's string
   let hours = Math.floor(sec / 3600) // get hours
   let minutes = Math.floor((sec - hours * 3600) / 60) // get minutes
   let seconds = sec - hours * 3600 - minutes * 60 //  get seconds
   // add 0 if value < 10; Example: 2 => 02
   if (hours < 10) {
      hours = "0" + hours
   }
   if (minutes < 10) {
      minutes = "0" + minutes
   }
   if (seconds < 10) {
      seconds = "0" + seconds
   }
   return hours + ":" + minutes + ":" + seconds // Return is HH : MM : SS
}

//@ts-nocheck
import React from "react"
import Loading from "../Loading"
import useTrip from "./rides/hooks/useTrip"
import TripDetailsV2 from "./rides/details/TripDetailsV2"
import TripDetailsV1 from "./rides/details/TripDetailsV1"

export default function ViewTripDetails({ match }) {
   const { trip, loading } = useTrip(match.params.id)
   if (loading) {
      return <Loading />
   }
   if (!trip) return <></>

   if (trip.version === 1) return <TripDetailsV2 trip={trip} />

   return <TripDetailsV1 trip={trip} />
}

//define trip interface
export interface Trip {
   tripId?: string
   //route array of destination objects
   route: Destination[]
   driverId: string
   addedAt: any
   lastUpdated: any
   currentPosition: number
   status: TripStatus
   waiting: boolean
   passenger: Passenger
   note: string
   show: boolean
   version: number
   waitingTimes: WaitingTime[]
   totalTimeInSeconds: number
   distanceCovered: number
   waitingTimeInSeconds: number
   dispatcherId: string
   id?: string
   driverFeedback?: string
   passengerFeedback?: string
   startedAt?: any
   partialDroppedOff?: boolean
   nextDestinationText?: string
   nextStatus?: DestinationStatus
   completedAt?: any
   type: TripType
}

export enum TripType {
   pending = 1,
   active = 2,
   completed = 3,
}

export interface TripRoute {
   title: string
   description: string
   time: any
   completed: boolean
   distance?: any
   status: DestinationStatus
   showEdit: any
   showAdd: any
   showDelete: any
   arriveTime: any
}

export interface WaitingUpdate {
   waiting: Boolean
   lastUpdated: any
   waitingTimes: WaitingTime[]
}

export interface TripUpdate {
   route?: Destination[]
   lastUpdated: any
   currentPosition?: number
   status?: TripStatus
   waiting?: boolean
   waitingTimes?: WaitingTime[]
   totalTimeInSeconds?: number
   waitingTimeInSeconds?: number
   startedAt?: any
   partialDroppedOff?: boolean
   nextDestinationText?: string
   nextStatus: DestinationStatus
   completedAt?: any
}

export interface Feedback {
   feedback: string
}

export interface WaitingTime {
   time: any
   waiting: boolean
}

export enum TripStatus {
   submited = 1,
   accepted = 2,
   started = 3,
   arrived = 4,
   completed = 5,
}

export interface Destination {
   type: DestinationType
   address: string
   arriveTime: any
   formattedArriveTime?: string
   startTime?: any
   formattedStartTime?: string
   endTime?: any
   formattedEndTime?: string
   partialDropoff?: boolean
   arrived: boolean
   status: DestinationStatus
}

export enum DestinationStatus {
   notStarted = 1,
   inRoute = 2,
   arrived = 3,
   onBoarding = 4,
   completed = 5,
   droppedOff = 6,
}

// enum of desination type
export enum DestinationType {
   pickup = 1,
   stop = 2,
   dropoff = 3,
}

export interface Passenger {
   name: string
   phone: string
   email: string
   id: string
   vip: boolean
}

export const getDestinationStatusColor = (status: DestinationStatus) => {
   switch (status) {
      case DestinationStatus.notStarted:
         return "red"
      case DestinationStatus.inRoute:
         return "magenta"
      case DestinationStatus.arrived:
         return "blue"
      case DestinationStatus.onBoarding:
         return "orange"
      case DestinationStatus.completed:
         return "green"
      case DestinationStatus.droppedOff:
         return "gold"
      default:
         return "lime"
   }
}

export const getDestinationStatusText = (status: DestinationStatus) => {
   if (!status) return null

   switch (status) {
      case DestinationStatus.notStarted:
         return "Not Started"
      case DestinationStatus.inRoute:
         return "In Route"
      case DestinationStatus.arrived:
         return "Arrived"
      case DestinationStatus.onBoarding:
         return "On Boarding"
      case DestinationStatus.completed:
         return "Completed"
      case DestinationStatus.droppedOff:
         return "Dropped Off"
   }

   return null
}

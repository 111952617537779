//@ts-nocheck
import React, { useEffect, useState } from "react"
import { Button, Form, Input, Select, Space, Table, Tag } from "antd"
import { useHistory } from "react-router"
import {
   collection,
   getFirestore,
   query,
   limit,
   orderBy,
} from "firebase/firestore"
import { getDispatcherId } from "../../firebase/profileManagement"
import { getRideStatusContent, rideStatus } from "../helpers/status"
import { ExportOutlined, FilterFilled, SearchOutlined } from "@ant-design/icons"
import { exportAllRides } from "../../firebase/rideMangement"
import Loading from "../Loading"
import TimeRenderer from "./rides/tracking/TimeRenderer"
import DriverInformation from "./rides/tracking/DriverInformation"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { Trip } from "../../types/trip"
import Tour from "reactour"
import {
   getTourDetails,
   setTour,
   setTourDetail,
} from "../helpers/reactTourHelper"

const steps = [
   {
      content: "Welcome to tracking of the trips!",
   },
   {
      selector: "#search-trip",
      content: "Search for a trip by Passenger name",
   },
   {
      selector: "#trip-status",
      content: "Filter trips by status",
   },
   {
      selector: "#export-trips",
      content: "Click here to export all trips",
   },
   {
      content: "That's it! You're done!",
   },
]

const { Option } = Select

export default function RideTracking() {
   const [form] = Form.useForm()
   const history = useHistory()
   const [filterBy, setFilterBy] = useState(0)
   const [trips, setTrips] = useState([])
   const [exporting, setExporting] = useState(false)
   const [tourEnabled, setTourEnabled] = useState(false)
   const [step, setStep] = useState(0)

   useEffect(() => {
      const loadTourDetail = async () => {
         const td = await getTourDetails()

         if (!td) return

         if (!td.tracking) {
            setTourEnabled(true)
         }

         setTour((e) => {
            const index = steps.findIndex((i) => i.selector === e)
            setStep(index + 1)
         })
      }

      loadTourDetail()
   }, [])

   const ViewDetails = (id) => {
      history.push({
         pathname: "/dashboard/ride-details/" + id,
         state: {
            parcelId: id,
         },
      })
   }

   const columns = [
      {
         title: "TripId",
         dataIndex: "tripId",
         key: "tripId",
         sorter: (a, b) =>
            parseInt(a.tripId?.replace("#", "") ?? "0") -
            parseInt(b.tripId?.replace("#", "") ?? "0"),
      },
      {
         title: "Passenger Name",
         dataIndex: ["passenger", "name"],
         key: "passengerName",
      },
      {
         title: "Driver Name",
         dataIndex: "driverId",
         key: "driverId",
         render: (driverId, record) => {
            return <DriverInformation driverId={driverId} />
         },
      },
      {
         title: "Status",
         dataIndex: "status",
         key: "status",
         render: (status, record) => {
            return (
               <Space size={"small"}>
                  {getRideStatusContent(status)}
                  {record.waiting ? <Tag color={"orange"}>Waiting</Tag> : <></>}
               </Space>
            )
         },
         sorter: (a, b) => a.status - b.status,
      },
      {
         title: "Pickup Date",
         dataIndex: "",
         key: "pickupDate",
         render: (seconds, record) => {
            if (record.version === 1) {
               const trip = record as Trip
               return <TimeRenderer date={trip.route[0].formattedArriveTime} />
            }

            return (
               <TimeRenderer
                  date={record.formatedDate?.pickup ?? new Date(seconds * 1000)}
               />
            )
         },
         sorter: (a, b) => a.pickupDate - b.pickupDate,
      },
      {
         title: "Pick up Location",
         dataIndex: "",
         key: "pickupLocation",
         render: (t, r) => {
            if (r.version === 1) {
               const trip = r as Trip
               return (
                  <p style={{ textTransform: "capitalize" }}>
                     {trip.route[0].address}
                  </p>
               )
            }

            return (
               <p style={{ textTransform: "capitalize" }}>{r.pickupLocation}</p>
            )
         },
      },
      {
         title: "Drop Off Location",
         dataIndex: "",
         key: "dropOffLocation",
         render: (t, r) => {
            if (r.version === 1) {
               const trip = r as Trip
               return (
                  <p style={{ textTransform: "capitalize" }}>
                     {trip.route[trip.route.length - 1].address}
                  </p>
               )
            }
            return (
               <p style={{ textTransform: "capitalize" }}>
                  {r.dropOffLocation}
               </p>
            )
         },
      },
      // {
      //    title: "Distance Covered",
      //    dataIndex: "distanceCovered",
      //    key: "distanceCovered",
      //    render: (t, r) => (
      //       <p style={{ textTransform: "capitalize" }}>
      //          {t ? `${(t / 1609.344).toFixed(2)} Miles` : "Unknown"}
      //       </p>
      //    ),
      // },
      {
         title: "Action",
         key: "action",
         render: (text, record) => (
            <Space size='middle'>
               <Button onClick={() => ViewDetails(record.id)} type='link'>
                  View
               </Button>
            </Space>
         ),
      },
   ]

   const [values, loading, error, snapshot] = useCollectionData(
      query(
         collection(getFirestore(), "trips"),
         limit(100),
         orderBy("addedAt", "desc")
      )
   )

   useEffect(() => {
      if (!snapshot) return
      setTrips(
         snapshot.docs.map((d) => {
            return {
               id: d.id,
               ...d.data(),
            }
         })
      )
   }, [snapshot])

   const [search, setSearch] = useState("")

   return (
      <>
         <Tour
            disableFocusLock={true}
            getCurrentStep={setStep}
            goToStep={step}
            steps={steps}
            isOpen={tourEnabled}
            finish
            lastStepNextButton={<Button>Finish</Button>}
            onRequestClose={(a) => {
               setTourDetail("tracking", true)
               setTourEnabled(false)
            }}
         />
         <div className='flex flex-col flex-1 h-full'>
            <div className='mb-2 p-6 pb-4 rounded-lg border bg-gradient-to-r from-blue-50 to-blue-200'>
               <h1
                  className='text-xl font-semibold mb-8'
                  style={{ color: "#272727" }}>
                  Search Trip
               </h1>

               <div className='flex flex-row justify-between'>
                  <div className='flex' id='search-trip'>
                     <Input
                        size='large'
                        onChange={(e) => {
                           setSearch(e.target.value)
                        }}
                        className='w-full'
                        prefix={<SearchOutlined />}
                        placeholder='Search Passenger'
                     />
                  </div>
                  <div id='trip-status'>
                     <Space direction='horizontal'>
                        <Select
                           size='large'
                           suffixIcon={<FilterFilled className='font-bold' />}
                           value={filterBy}
                           onChange={(e) => {
                              setFilterBy(e)
                           }}
                           className='w-32'>
                           <Option value={0}>All</Option>
                           <Option value={rideStatus.submited}>Pending</Option>
                           <Option value={rideStatus.accepted}>Accepted</Option>
                           <Option value={rideStatus.started}>Started</Option>
                           <Option value={rideStatus.arrived}>Arrived</Option>
                           <Option value={rideStatus.completed}>
                              Completed
                           </Option>
                        </Select>
                     </Space>
                  </div>
               </div>
            </div>
            <div className='justify-end mb-2  flex flex-row'>
               <Button
                  id='export-trips'
                  onClick={async () => {
                     setExporting(true)
                     await exportAllRides()
                     setExporting(false)
                  }}
                  loading={exporting}
                  type='link'
                  icon={<ExportOutlined />}>
                  Export
               </Button>
            </div>
            {loading ? (
               <Loading />
            ) : (
               <Table
                  dataSource={trips
                     .filter((trip) => {
                        if (
                           getDispatcherId() !==
                              "po5K9KTDF7SlW1nr6kX2eU6VsFp1" &&
                           trip.dispatcherId === "po5K9KTDF7SlW1nr6kX2eU6VsFp1"
                        )
                           return false

                        if (filterBy === 0) {
                           return trip.passenger.name
                              .toLowerCase()
                              .includes(search.toLowerCase())
                        }

                        if (filterBy === trip.status) {
                           return trip.passenger.name
                              .toLowerCase()
                              .includes(search.toLowerCase())
                        }
                        return false
                     })
                     .sort(
                        (a, b) =>
                           parseInt(b.tripId?.replace("#", "") ?? "0") -
                           parseInt(a.tripId?.replace("#", "") ?? "0")
                     )}
                  columns={columns}
                  rowKey='id'
               />
            )}
         </div>
      </>
   )
}

import React, { useState } from "react"
import { useEffect } from "react"
import { getDriverProfilePicture } from "../../../firebase/chatController"
import fakeProfile from "../../../assets/fakeprofile.png"
import { Avatar } from "antd"

export default function Conversation({ user, selected, onClick }) {
   const [userProfile, setUserProfile] = useState(null)
   const [userId, setUserId] = useState(user.userId)

   useEffect(() => {
      const loadData = async () => {
         if (user) {
            getDriverProfilePicture(user.userId).then((url) => {
               setUserProfile(url)
            })
         }
      }

      loadData()
   }, [userId])

   return (
      <div
         onClick={() => {
            if (selected) return
            if (onClick) onClick()
         }}
         className={`rouned-lg p-2 flex items-center flex-row mt-1 mb-1 conversation-item ${
            selected ? "conversation-item-selected" : ""
         }`}>
         <div className='mr-2'>
            <Avatar width={30} height={30} src={userProfile} size='large'>
               <span className='text-lg'>
                  {user.name.charAt(0).toUpperCase()}
               </span>
            </Avatar>
         </div>
         <div className='flex-1 flex flex-col justify-center '>
            <h1 style={{ fontSize: "14px" }} className='p-0 m-0 font-normal'>
               {user.name}
            </h1>
            <div className='flex flex-row items-center'>
               <div
                  style={{ width: "8px", height: "8px" }}
                  className={
                     "mr-1 rounded-full " +
                     (user.online ? "bg-green-500" : "bg-red-500")
                  }></div>
               <h6
                  style={{ fontSize: "11px" }}
                  className='p-0 m-0  font-normal'>
                  {user.online ? "Online" : "Offline"}
               </h6>
            </div>
         </div>
      </div>
   )
}

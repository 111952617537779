import React, { useEffect, useState } from "react"
import ChatBox from "./ChatBox"
import ConversationList from "./ConversationList"
import { Button } from "antd"
import { IoSend } from "react-icons/io5"
import {
   addMessageToChat,
   getDriverDetail,
} from "../../../firebase/chatController"

export default function ChatContainer({
   chatId,
   conversationLoading,
   conversations,
}) {
   const [selectedChatId, setSelectedChatId] = useState(null)
   const [users, setUsers] = useState([])
   const [text, setText] = useState("")
   const [noOfConversations, setNoOfConversations] = useState(0)
   const [loadingDriverDetails, setLoadingDriverDetails] = useState(false)
   const [isFirst, setIsFirst] = useState(true)

   useEffect(() => {
      if (chatId && conversations.some((c) => c.userId === chatId)) {
         setSelectedChatId(
            conversations.find((c) => c.userId === chatId).chatId
         )
      } else setSelectedChatId(null)
   }, [chatId, conversations])

   useEffect(() => {
      const loadData = async () => {
         if (isFirst) {
            setLoadingDriverDetails(true)
         }

         const localUsers = []
         for (const c of conversations) {
            const user = await getDriverDetail(c.userId)
            if (!user) continue
            localUsers.push({
               ...user,
               chatId: c.chatId,
               userId: c.userId,
            })
         }
         setLoadingDriverDetails(false)
         setIsFirst(false)

         setUsers(localUsers)
      }

      if (conversations && conversations.length > 0) {
         loadData()
         setNoOfConversations(conversations.length)
      }
   }, [conversations])

   const sendMessage = async () => {
      if (text.trim() === "") return

      const t = text
      setText("")
      goToBottom()
      await addMessageToChat(selectedChatId, t)
   }

   const goToBottom = () => {
      var doc = document.getElementById("main-chat")
      if (doc) {
         doc.scrollTop = doc.scrollHeight
      }
   }

   return (
      <div className='rounded-xl m-2 flex-row flex flex-1'>
         <ConversationList
            conversationLoading={loadingDriverDetails}
            mainLoading={conversationLoading}
            noOfConversations={noOfConversations}
            users={users}
            selectedConversation={chatId}
            className='chat-side rounded-lg overflow-auto'
         />
         <div className='flex flex-1 rounded-lg flex-col mr-1 ml-1 p-1'>
            {selectedChatId ? (
               <>
                  <div className='overflow-auto flex-1' id='main-chat'>
                     <ChatBox chatId={selectedChatId} />
                  </div>
                  <div className='flex flex-col mt-2 ml-2 mr-2'>
                     <div className='flex flex-row'>
                        <input
                           value={text}
                           onKeyDown={async (e) => {
                              if (e.key === "Enter") {
                                 await sendMessage()
                              }
                           }}
                           onChange={(e) => setText(e.target.value)}
                           className='flex-1 mr-2 border-0 outline-none'
                           placeholder='Write message'
                        />
                        <Button
                           onClick={async () => {
                              await sendMessage()
                           }}
                           type='primary'>
                           <IoSend className='text-white text-lg' />
                        </Button>
                     </div>
                     <div className='send-input-border mt-2' />
                  </div>
               </>
            ) : (
               <div className='flex-1 flex justify-center items-center'>
                  <h1 className='text-lg'>Select Chat</h1>
               </div>
            )}
         </div>
      </div>
   )
}

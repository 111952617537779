import { collection, getDocs, getFirestore, query } from "firebase/firestore"
import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { getAuth, sendPasswordResetEmail } from "firebase/auth"
import { errorResponse, okResponse } from "../components/helpers/serverResponse"
var AsyncLock = require("async-lock")
var lock = new AsyncLock()

export async function getDriverProfilePicture(id) {
   await lock.acquire(
      "driver-profile-" + id,
      async (d) => {
         const isItemExist =
            sessionStorage.getItem("driver-profile-" + id) !== null

         if (isItemExist) return

         const storage = getStorage()
         const fr = ref(storage, "profile-pictures/" + id + ".png")

         const url = await new Promise((resolve, reject) => {
            getDownloadURL(fr)
               .then((url) => {
                  resolve(url)
               })
               .catch((err) => {
                  resolve(null)
               })
         })

         sessionStorage.setItem("driver-profile-" + id, url ? url : "")
         d()
      },
      () => {
         // console.log(id + " done")
      }
   )

   return sessionStorage.getItem("driver-profile-" + id)
}

export async function getAllDrivers() {
   try {
      const db = getFirestore()
      const data = []
      const docs = await getDocs(query(collection(db, "drivers")))

      docs.forEach((x) => {
         data.push({
            id: x.id,
            ...x.data(),
         })
      })

      return data
   } catch (e) {
      return []
   }
}

export async function sendResetPasswordLink(email) {
   try {
      await sendPasswordResetEmail(getAuth(), email)
      return okResponse("Email sent successfully")
   } catch (e) {
      if (e.code === "auth/user-not-found") {
         return errorResponse("User not found", [], null)
      }

      return errorResponse("Unknow error", [], null)
   }
}

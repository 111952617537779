import { Input, Skeleton } from "antd"
import React, { useState } from "react"
import Conversation from "./Conversation"
import { useHistory } from "react-router-dom"
import Loading from "../../Loading"
export default function ConversationList({
   className = "",
   users,
   onSelect,
   onConversationChanged,
   selectedConversation,
   conversationLoading,
   mainLoading,
   noOfConversations,
   ...props
}) {
   const history = useHistory()
   const [searchText, setSearchText] = useState("")

   return (
      <div {...props} className={"flex flex-col " + className}>
         {mainLoading ? (
            <Loading />
         ) : conversationLoading ? (
            <>
               {Array.from(Array(noOfConversations).keys()).map((i) => (
                  <Skeleton
                     loading={true}
                     className='ml-3 mt-2 mb-1'
                     active={true}
                     avatar
                     paragraph={{ rows: 0 }}
                  />
               ))}
            </>
         ) : (
            <>
               <div className='flex flex-1 flex-col'>
                  <div className='p-2'>
                     <Input
                        placeholder='Search'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className=' '
                     />
                  </div>
                  <div className='flex flex-1 flex-col'>
                     {users
                        .filter((u) =>
                           u.name
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                        )
                        .map((u) => {
                           return (
                              <Conversation
                                 key={u.userId}
                                 onClick={() => {
                                    history.push(
                                       `/dashboard/messages/${u.userId}`
                                    )
                                 }}
                                 user={u}
                                 selected={u.userId === selectedConversation}
                              />
                           )
                        })}
                  </div>
               </div>
            </>
         )}
      </div>
   )
}

import { Avatar } from "antd"
import React from "react"
import { useState, useEffect } from "react"
import { getDriverProfilePicture } from "../../../../firebase/driverManagement"

export default function DriversMapPointer({
   onEnter,
   onLeave,
   driverId,
   level,
   name = "A",
}) {
   const [image, setImage] = useState(null)
   useEffect(() => {
      const load = async () => {
         var i = await getDriverProfilePicture(driverId)

         setImage(i)
      }

      load()
   }, [driverId])
   return (
      <div
         onMouseEnter={() => {
            onEnter?.()
         }}
         onMouseLeave={() => {
            onLeave?.()
         }}
         style={{
            background: "transparent",
         }}>
         <Avatar
            src={image}
            style={{
               width: "23px",
               height: "23px",
               position: "absolute",
               marginTop: "5px",
               marginLeft: "6px",
            }}>
            <p className='m-0 text-sm text-center'>{name[0]}</p>
         </Avatar>
         <svg
            key={driverId + "-svg"}
            width='35'
            height='49.88'
            className=''
            viewBox='0 0 120 171'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            xlinkHref='http://www.w3.org/1999/xlink'>
            <path
               d='M60 0C32.3082 0 0 16.9461 0 60.0814C0 89.3509 46.1541 154.054 60 171C72.3078 154.054 120 90.8922 120 60.0814C120 16.9461 87.6918 0 60 0Z'
               fill={
                  level === 1 ? "#F5BA40" : level === 2 ? "#448EF7" : "#DB673A"
               }
            />
         </svg>
      </div>
   )
}

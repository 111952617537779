import { initializeApp } from "firebase/app"
import {
   getMessaging,
   getToken,
   onMessage,
   isSupported,
} from "firebase/messaging"
import {
   addDoc,
   doc,
   getDoc,
   getFirestore,
   setDoc,
   updateDoc,
} from "firebase/firestore"
import { getDispatcherId } from "./profileManagement"
import {
   sendInfoNotification,
   sendMessageNotification,
} from "../components/helpers/notification"
import React from "react"
import MoveToChat from "../components/dispatcher/messages/MoveToChat"

const firebaseConfig = {
   apiKey: "AIzaSyBSEbRfLxqOQbR4tPOQolioMFbpjCFRNN4",
   authDomain: "lrcarservnew-370105.firebaseapp.com",
   projectId: "lrcarservnew-370105",
   storageBucket: "lrcarservnew-370105.appspot.com",
   messagingSenderId: "14144424817",
   appId: "1:14144424817:web:1c470b94d11c99d5f184d0",
   measurementId: "G-9Z8WRR3G2N",
}

var historyInstance
export function setHistory(history) {
   historyInstance = history
}

export const app = initializeApp(firebaseConfig)

isSupported().then((is) => {
   if (is) {
      const messaging = getMessaging(app)

      onMessage(messaging, (payload) => {
         if (payload && payload.data) {
            if (payload.data.type === "newChatMessage") {
               sendMessageNotification(
                  payload.notification.body,
                  payload.notification.title,
                  {
                     btn: (
                        <MoveToChat
                           history={historyInstance}
                           chatId={payload.data.chatId}
                        />
                     ),
                  }
               )
            }
         }
      })
   } else console.log("fcm not supported")
})

export function updateFcmToken() {
   isSupported().then((is) => {
      if (!is) return
      const messaging = getMessaging(app)
      getToken(messaging, {
         vapidKey:
            "BGwdk6wLA8-qoylPSt1Lk-AwoVeWwUm_dn96DC66dXULttotOLCRll-Apae--uaSQjukkPodcUiXnEwUB_6y_9A",
      })
         .then(async (t) => {
            var d = await getDoc(
               doc(getFirestore(), "fcmTokens", getDispatcherId())
            )
            if (d.exists()) {
               await updateDoc(
                  doc(getFirestore(), "fcmTokens", getDispatcherId()),
                  {
                     tokens: [...new Set([...d.data().tokens, t])],
                  }
               )
            } else {
               setDoc(doc(getFirestore(), "fcmTokens", getDispatcherId()), {
                  dispatcherId: getDispatcherId(),
                  tokens: [t],
               })
            }
         })
         .catch((e) => {
            console.log(e)
         })
   })
}
export default app

import React, { useEffect, useState } from "react"
import ChatContainer from "./ChatContainer"
import { useCollection } from "react-firebase-hooks/firestore"
import { collection, orderBy, where } from "firebase/firestore"
import { getFirestore } from "firebase/firestore"
import { query } from "firebase/firestore"
import { getDispatcherId } from "../../../firebase/profileManagement"
import { useParams } from "react-router-dom"
import { startNewChatWithDriver } from "../../../firebase/chatController"
export default function MessageView() {
   const params = useParams()

   const [chats, loading, error] = useCollection(
      query(
         collection(getFirestore(), "chats"),
         where("members", "array-contains", getDispatcherId()),
         orderBy("lastMessageTime", "desc")
      )
   )

   const [conversations, setConversations] = useState([])

   const [addingConversation, setAddingConversation] = useState(true)

   useEffect(() => {
      setConversations(
         chats?.docs.map((c) => {
            var data = c.data()
            if (data.members.length < 1) return null

            return {
               userId: data.members.find((m) => m !== getDispatcherId()),
               chatId: c.id,
            }
         }) ?? []
      )
   }, [chats])

   const [isAddingNewDriver, setIsAddingNewDriver] = useState(false)

   const addNewDriver = async (id) => {
      setIsAddingNewDriver(true)
      const result = await startNewChatWithDriver(id)
      setIsAddingNewDriver(false)
      setAddingConversation(false)
   }

   useEffect(() => {
      if (!loading && params.id) {
         const isThere = conversations.some((c) => c.userId === params.id)
         if (!isThere && !isAddingNewDriver) {
            addNewDriver(params.id)
         } else setAddingConversation(false)
      } else if (!loading) {
         setAddingConversation(false)
      }
   }, [conversations, loading])

   return (
      <div className='flex flex-1 h-full w-full'>
         <ChatContainer
            chatId={params.id}
            conversationLoading={addingConversation}
            conversations={conversations}
         />
      </div>
   )
}

import "./App.css"
import "./styles/css/antd.css"
import "intro.js/introjs.css"
import Layout from "./components/helpers/LayoutDesign"
import { Redirect } from "react-router"
import { Route, BrowserRouter as Router, Switch } from "react-router-dom"
import DispatcherDashboard from "./components/dispatcher/DispatcherDashboard"
import RideTracking from "./components/dispatcher/RideTracking"
import Login from "./components/authentication/Login"
import Register from "./components/authentication/Register"
import Passengers from "./components/dispatcher/passengers/Passengers"
import "./firebase/firebase"
import MessageView from "./components/dispatcher/messages/MessageView"
import ForgotPassword from "./components/authentication/ForgotPassword"
import SettingsView from "./components/dispatcher/settings/SettingsView"
import ViewTripDetails from "./components/dispatcher/ViewTripDetails"
import { useEffect, useState } from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"
import { loadSettings } from "./firebase/settingsController"
import { loadTimezones } from "./firebase/profileManagement"

const errorsToWarn = ["Warning:"]
const oldConsError = console.error
console.error = function (...args) {
   let toWarn = false

   if (typeof args[0] === "string") {
      errorsToWarn.map(function (_s) {
         if (args[0].startsWith(_s)) {
            toWarn = true
         }
      })
   }

   toWarn ? console.warn(...args) : oldConsError(...args)
}

function App() {
   const [loading, setLoading] = useState(true)

   useEffect(() => {
      loadTimezones().then(() => {
         setLoading(false)
      })
   }, [])

   if (loading)
      return (
         <div className='flex items-center justify-center h-full w-full'>
            <Spin indicator={<LoadingOutlined />} size='large' />
         </div>
      )

   return (
      <div style={{ fontFamily: "Inter" }}>
         <Router>
            <Switch>
               <Route exact path='/login' component={Login} />
               <Route exact path='/register' component={Register} />
               <Route
                  exact
                  path='/forgot-password'
                  component={ForgotPassword}
               />
               <Route path='/dashboard'>
                  <Layout>
                     <Switch>
                        <Route
                           exact
                           path='/dashboard'
                           component={DispatcherDashboard}
                        />
                        <Route
                           exact
                           path='/dashboard/rides'
                           component={RideTracking}
                        />
                        <Route
                           exact
                           path='/dashboard/messages/:id?'
                           component={MessageView}
                        />
                        <Route
                           exact
                           path='/dashboard/passengers'
                           component={Passengers}
                        />

                        <Route
                           exact
                           path='/dashboard/settings'
                           component={SettingsView}
                        />

                        <Route
                           exact
                           path='/dashboard/ride-details/:id'
                           component={ViewTripDetails}
                        />

                        <Redirect to='/dashboard/rides' />
                     </Switch>
                  </Layout>
               </Route>

               <Redirect to='/login' />
            </Switch>
         </Router>
      </div>
   )
}

export default App

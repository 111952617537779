import { doc, setDoc, getFirestore } from "firebase/firestore"

export async function updateFeedback(tripId, from, feedback) {
   var updates = null

   if (from === "driver") {
      updates = {
         driverFeedback: feedback,
      }
   } else
      updates = {
         passengerFeedback: feedback,
      }
   try {
      await setDoc(doc(getFirestore(), "trips", tripId), updates, {
         merge: true,
      })
      return true
   } catch (e) {
      return false
   }
}

import { Button, Col, Form, Input, Modal, Row, Switch } from "antd"
import React, { useEffect, useState } from "react"
import {
   addPassenger,
   updatePassenger,
} from "../../../firebase/passengerManagement"
import {
   sendErrorNotification,
   sendSuccessNotification,
} from "../../helpers/notification"
import { UploadOutlined, UserAddOutlined } from "@ant-design/icons"
import { onReactTourActionPerformed } from "../../helpers/reactTourHelper"

export default function ViewUpdatePassenger({
   type = "add",
   passenger,
   onUpdate,
   onAdd,
   size = "large",
   addButtonText = "Add",
}) {
   const [visible, setVisible] = useState(false)
   const [loading, setLoading] = useState(false)
   const [form] = Form.useForm()

   useEffect(() => {
      if (passenger == null)
         form.setFieldsValue({
            name: "",
            email: "",
            phone: "",
            company: "",
            vip: false,
         })
      else form.setFieldsValue(passenger)
   }, [passenger])

   const onFinish = async (vs) => {
      setLoading(true)
      if (type === "add") {
         const id = await addPassenger(vs)
         if (id) {
            sendSuccessNotification("Passenger added successfully")
            onAdd({
               id: id,
               ...vs,
            })
            setVisible(false)
            onReactTourActionPerformed(".submit-add-passenger-button")
         } else sendErrorNotification("Error adding passenger")
      } else if (type === "update") {
         const response = await updatePassenger(passenger.id, vs)

         if (response) {
            if (onUpdate !== null) onUpdate(vs)
            setLoading(false)
            setVisible(false)
         } else {
            sendErrorNotification("Unable to update passenger")
         }
      }
      setLoading(false)
   }

   return (
      <>
         <Modal
            visible={visible}
            title={(type === "add" ? "Add" : "Update") + "  Passenger"}
            okText={type === "add" ? "Add" : "Update"}
            onCancel={() => setVisible(false)}
            onOk={() => {
               form.submit()
            }}
            className='w-3/6'
            okButtonProps={{
               className: "submit-add-passenger-button",
               loading: loading,
               icon:
                  type === "update" ? <UploadOutlined /> : <UserAddOutlined />,
            }}>
            <div className='h-full w-full flex flex-col add-passenger-form'>
               <Form form={form} layout='vertical' onFinish={onFinish}>
                  <Row>
                     <Col span={11}>
                        <Form.Item
                           name={"name"}
                           rules={[
                              {
                                 required: true,
                                 message: "Name is required",
                                 min: "4",
                                 max: "20",
                              },
                           ]}
                           label='Name'>
                           <Input
                              size='large'
                              placeholder='Enter passengers name'
                           />
                        </Form.Item>
                     </Col>
                     <Col offset={2} span={11}>
                        <Form.Item
                           name={"email"}
                           rules={[
                              {
                                 required: false,
                                 message: "Email is required",
                                 type: "email",
                              },
                           ]}
                           label='Email'>
                           <Input
                              size='large'
                              type={"email"}
                              placeholder='Enter passengers email'
                           />
                        </Form.Item>
                     </Col>
                  </Row>
                  <Row>
                     <Col span={11}>
                        <Form.Item
                           name={"phone"}
                           rules={[
                              {
                                 message: "Phone number is required",
                              },
                           ]}
                           label='Phone'>
                           <Input
                              size='large'
                              required={false}
                              placeholder='921234567890'
                           />
                        </Form.Item>
                     </Col>
                     <Col offset={2} span={11}>
                        <Form.Item
                           name={"company"}
                           rules={[
                              {
                                 message: "Company Name Required",
                              },
                           ]}
                           label='Company Name'>
                           <Input
                              size='large'
                              required={false}
                              placeholder='Enter passengers company name'
                           />
                        </Form.Item>
                     </Col>
                  </Row>
                  <Row>
                     <Col span={11}>
                        <Form.Item
                           name={"vip"}
                           valuePropName='checked'
                           label='Vip'>
                           <Switch />
                        </Form.Item>
                     </Col>
                  </Row>
               </Form>
            </div>
         </Modal>
         {type === "add" ? (
            <Button
               type='primary'
               id='add-passenger-button'
               icon={<UserAddOutlined />}
               onClick={() => {
                  onReactTourActionPerformed("#add-passenger-button")
                  setVisible(true)
               }}
               size={size}>
               {addButtonText}
            </Button>
         ) : (
            <Button
               type='link'
               onClick={() => {
                  setVisible(true)
               }}>
               Update
            </Button>
         )}
      </>
   )
}

import React, { useEffect } from "react"
import { Image, Menu, Button } from "antd"
import { Link, useHistory } from "react-router-dom"
import { getAuth, signOut } from "@firebase/auth"
import { useLocation } from "react-router-dom"
import {
   MdPeopleAlt,
   MdFlag,
   MdSpaceDashboard,
   MdSettings,
} from "react-icons/md"
import LrLogo from "../../assets/lrlogocolor.svg"
import { MessageFilled } from "@ant-design/icons"
import { setHistory } from "../../firebase/firebase"

export default function SideNav({ isCollapsed }) {
   const location = useLocation()
   const logoSize = isCollapsed ? "w-16" : "w-28"
   const collapseYPadding = isCollapsed ? "pb-10 pt-5" : "py-8"

   const history = useHistory()

   useEffect(() => {
      setHistory(history)
   }, [history])

   const Logout = async () => {
      try {
         const auth = await getAuth()
         signOut(auth)
         history.push("login")
      } catch (error) {}
   }

   const navItems = [
      {
         name: "Dashboard",
         path: "/dashboard",
         icon: MdSpaceDashboard,
         key: "1",
      },
      {
         name: "Passengers",
         path: "/dashboard/passengers",
         icon: MdPeopleAlt,
         key: "2",
      },
      {
         name: "Messages",
         path: "/dashboard/messages",
         icon: MessageFilled,
         key: "3",
      },
      {
         name: "Tracking",
         path: "/dashboard/rides",
         icon: MdFlag,
         key: "4",
      },
      {
         name: "Settings",
         path: "/dashboard/settings",
         icon: MdSettings,
         key: "5",
      },
   ]

   return (
      <div className='flex flex-col h-full'>
         <center className='relative my-16 '>
            {/* <div className='absolute top-7 left-7'>
               <Image
                  className={`duration-200 transition-all ${caroutlineSize}`}
                  src={CarOutline}
                  preview={false}
               />
            </div> */}
            <Image
               className={`duration-200 transition-all ${logoSize}`}
               src={LrLogo}
               preview={false}
            />
         </center>

         <Menu
            className='flex-1'
            theme='dark'
            selectedKeys={
               navItems.find((item) => item.path === location.pathname)?.key
            }
            mode='inline'>
            {navItems.map((i) => {
               return (
                  <Menu.Item
                     className={`px-8 text-base ${collapseYPadding}`}
                     icon={<i.icon className='text-2xl' />}
                     key={i.key}>
                     <Link to={i.path}>{i.name}</Link>
                  </Menu.Item>
               )
            })}
         </Menu>

         <center className='mb-8'>
            <Button danger type='text' onClick={() => Logout()}>
               Logout
            </Button>

            <p className='text-white'>V1.0.6</p>
         </center>
      </div>
   )
}

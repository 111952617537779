import { Button, Modal, Popover } from "antd"
import React, { useState } from "react"
import DriversMapPointer from "./maps/DriversMapPointer"
import Driver from "./Driver"
import CustomMap from "../maps/CustomMap"
import InfoBox from "react-google-maps/lib/components/addons/InfoBox"
import { DirectionsRenderer } from "react-google-maps"

export default function DriversOnMap({
   drivers,
   showFullInfo = false,
   buttonText = "View",
   directShow = false,
   customItems = [],
   directions = [],
   mapClassName = "h-96",
   showMapOnNoDriver = false,
   center,
}) {
   const [showToolTipId, setShowToolTipId] = useState("")
   const [visible, setVisible] = useState(false)
   drivers = drivers.filter((d) => d.location).filter((d) => d !== null)

   if (drivers.length === 0) {
      if (showMapOnNoDriver) {
         //random lat lng
         const lat = Math.random() * (90 - -90 + 1) + -90
         const lng = Math.random() * (180 - -180 + 1) + -180
         return (
            <CustomMap
               defaultCenter={{
                  lat,
                  lng,
               }}
            />
         )
      } else return <></>
   }

   const getDrivers = () => {
      return drivers.map((d, k) => {
         const level = d.verification.level
         return (
            <InfoBox
               options={{
                  closeBoxURL: "",
                  enableEventPropagation: true,
               }}
               position={{
                  lat: d.location?.lat,
                  lng: d.location?.long,
               }}>
               <Popover
                  lat={d.location.lat}
                  key={"pointer-tool-" + d.id}
                  lng={d.location.long}
                  visible={showToolTipId === d.id}
                  content={
                     showFullInfo ? (
                        <Driver size='small' driver={d} />
                     ) : (
                        <p className='m-0 text-base'>{d.fullname}</p>
                     )
                  }
                  className='flex flex-col items-center'>
                  <DriversMapPointer
                     name={d.fullname}
                     image={d.image}
                     onEnter={() => {
                        setShowToolTipId(d.id)
                     }}
                     onLeave={() => {
                        setShowToolTipId("")
                     }}
                     key={"pointer-" + d.id}
                     driverId={d.id}
                     level={level}
                  />
               </Popover>
            </InfoBox>
         )
      })
   }

   const gmap = (
      <>
         <div className={"w-full " + mapClassName}>
            <CustomMap
               defaultZoom={drivers.length > 1 ? 8 : 13}
               {...(center
                  ? {
                       center: center,
                    }
                  : {})}
               defaultCenter={{
                  lat: drivers[0].location.lat,
                  lng: drivers[0].location.long,
               }}>
               {getDrivers()}
               {customItems.map((item, k) => {
                  return item
               })}
               {directions.map((d) => {
                  return (
                     <DirectionsRenderer
                        options={{
                           polylineOptions: {
                              strokeColor: d.color,
                              strokeOpacity: 0.7,
                              strokeWeight: 5,
                           },
                           suppressMarkers: true,
                        }}
                        directions={d.route}
                     />
                  )
               })}
            </CustomMap>
         </div>
      </>
   )

   if (directShow) return gmap

   return (
      <div>
         <Modal
            className='w-3/5'
            onCancel={() => {
               setVisible(false)
            }}
            id='driverOnMap'
            bodyStyle={{
               margin: 0,
               padding: 0,
               overflow: "hidden",
            }}
            visible={visible}
            title={
               <div className='flex flex-row items-center'>
                  <p className='m-0 mr-2'>Map</p>
                  {/* level === 1 ? "" : level === 2 ? "#448EF7" : "#DB673A" */}
                  <div className='flex flex-row space-x-2 items-center border border-solid px-2 rounded-sm'>
                     <div className='flex flex-row items-center'>
                        <div
                           className='w-3 mr-1 h-3 rounded-sm '
                           style={{ background: "#F5BA40" }}></div>
                        <p className='m-0'>Level 1</p>
                     </div>
                     <div className='flex flex-row items-center'>
                        <div
                           className='w-3 mr-1 h-3 rounded-sm '
                           style={{ background: "#448EF7" }}></div>
                        <p className='m-0'>Level 2</p>
                     </div>
                     <div className='flex flex-row items-center'>
                        <div
                           className='w-3 mr-1 h-3 rounded-sm '
                           style={{ background: "#DB673A" }}></div>
                        <p className='m-0'>Level 3</p>
                     </div>
                  </div>
               </div>
            }
            footer={null}>
            {visible ? gmap : null}
         </Modal>
         <Button type='primary' size='small' onClick={() => setVisible(true)}>
            {buttonText}
         </Button>
      </div>
   )
}

import React, { useEffect, useState } from "react"
import StartRideForm from "./rides/StartRideForm"
import ActiveDrivers from "./rides/ActiveDrivers"
import { Button, Col, Row } from "antd"
import { updateFcmToken } from "../../firebase/firebase"
import { useHistory } from "react-router-dom"
import { loadTimezones } from "../../firebase/profileManagement"
import Tour from "reactour"
import {
   getTourDetails,
   setTour,
   setTourDetail,
} from "../helpers/reactTourHelper"

const steps = [
   {
      content: "Let's start a trip!",
   },
   {
      selector: "#select-passenger-button",
      content: "Click here to select a passenger",
   },
   {
      selector: ".select-passenger-table",
      content: "Double click on a passenger to select them",
   },
   {
      selector: "#select-driver-button",
      content: "Click here to select driver",
   },
   {
      selector: ".select-driver-table",
      content: "Double click to select driver",
   },
   {
      selector: ".start-form-pickup-location",
      content: "Enter the pickup location",
   },
   {
      selector: ".start-form-dropoff-location",
      content: "Enter the dropoff location",
   },
   {
      selector: ".start-form-pickup-date",
      content: "Enter the pickup date",
   },
   {
      selector: "#start-form-schedule-ride",
      content: "Click here to schedule the ride",
   },
]

export default function DispatcherDashboard() {
   const history = useHistory()
   const [step, setStep] = useState(0)
   const [tourEnabled, setTourEnabled] = useState(false)

   useEffect(() => {
      updateFcmToken()
   }, [history])
   useEffect(() => {
      loadTimezones()
   })

   useEffect(() => {
      setTour((e) => {
         //get index of step where e == selector
         var index = steps.findIndex((x) => x.selector === e)
         setStep(index + 1)
      })

      const loadTourDetails = async () => {
         const td = await getTourDetails()

         if (!td)
            //exception occured
            return

         setTourEnabled(!td.dashboard)
      }

      loadTourDetails()
   }, [])

   const [pickupLocation, setPickupLocation] = useState(null)
   return (
      <>
         <Tour
            getCurrentStep={setStep}
            goToStep={step}
            steps={steps}
            isOpen={tourEnabled}
            finish
            lastStepNextButton={<Button>Finish</Button>}
            onRequestClose={async (a) => {
               setTourEnabled(false)
               await setTourDetail("dashboard", true)
            }}
         />
         <div className='h-full max-h-full'>
            <Row className='flex-1 h-full max-h-full overflow-scroll no-scroll-container'>
               <Col
                  xs={24}
                  md={24}
                  sm={24}
                  lg={12}
                  xl={12}
                  className='flex mb-2 max-h-full p-0 m-0 overflow-scroll'>
                  <div className='flex flex-1 xl:flex-auto rounded-xl'>
                     <StartRideForm
                        onPickupLocationChange={(p) => {
                           setPickupLocation(p)
                        }}
                     />
                  </div>
               </Col>
               <Col xs={24} md={24} sm={24} lg={1} xl={1} />
               <Col
                  xs={24}
                  md={24}
                  sm={24}
                  lg={11}
                  xl={11}
                  className='flex mb-2 max-h-full'>
                  <div
                     className='flex flex-1 p-8 rounded-xl border'
                     style={{ background: "#F7F8FC" }}>
                     <ActiveDrivers pickupLocation={pickupLocation} />
                  </div>
               </Col>
            </Row>
         </div>
      </>
   )
}

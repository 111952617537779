import React, { useEffect, useState } from "react"
import { Layout } from "antd"
import "./LayoutStyles.css"
import SideNav from "./SideNav"
import LayoutHeader from "./LayoutHeader"
import Loading from "../Loading"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { useHistory } from "react-router-dom"
import { getDispatcherDetails } from "../../firebase/profileManagement"
import { dispatcherStatus } from "./status"
import { startSettingListner, stopListner } from "./rideListener"
import { loadSettings } from "../../firebase/settingsController"

const { Header, Sider, Content } = Layout

export default function LayoutDesign(props) {
   const [checking, setChecking] = useState(true)
   const [isCollapsed, setIsCollapsed] = useState(false)

   const history = useHistory()

   useEffect(() => {
      onAuthStateChanged(getAuth(), async (user) => {
         if (!user) {
            history.push("/login")
            return
         }

         const response = await getDispatcherDetails()

         if (
            response &&
            response.verification &&
            response.verification.status === dispatcherStatus.approved
         ) {
            await loadSettings()
            startSettingListner()
            setChecking(false)
         } else history.push("/login")
      })

      return () => {
         stopListner()
      }
   }, [])

   if (checking) {
      return (
         <div className='h-screen'>
            <Loading />
         </div>
      )
   }
   return (
      <div>
         <Layout style={{ height: "100vh" }}>
            <Sider
               width={250}
               onCollapse={() => setIsCollapsed(!isCollapsed)}
               collapsible
               breakpoint='xl'>
               <SideNav isCollapsed={isCollapsed} />
            </Sider>
            <Layout
               className='p-10 pb-2 pt-5'
               style={{ backgroundColor: "#fff" }}>
               <Header className='p-0 mb-1' style={{ backgroundColor: "#fff" }}>
                  <LayoutHeader />
               </Header>
               <Content
                  style={{ background: "#fff" }}
                  className='overflow-y-auto mt-4 rounded-xl no-scroll-container'>
                  {props.children}
               </Content>
            </Layout>
         </Layout>
      </div>
   )
}

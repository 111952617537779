import React, { useEffect } from "react"
import { getDispatcherId } from "../../../firebase/profileManagement"
import moment from "moment"
export default function ChatMessage({ id, message, onLoaded }) {
   useEffect(() => {
      if (onLoaded) onLoaded()
   }, [])
   return (
      <div
         id={id}
         className={
            "flex m-2 mb-1 flex-col " +
            (message.sentBy === getDispatcherId()
               ? "justify-end items-end"
               : "justify-start items-start")
         }>
         <div
            className={
               "chat-message rounded-lg justify-center p-3 " +
               (message.sentBy === getDispatcherId() ? "bg-blue-100" : "")
            }>
            <p className='text-sm m-0'>{message.message}</p>
         </div>
         <p className='m-0 p-0 text-xs mt-1 opacity-50'>
            {moment(new Date(message.timestamp)).fromNow()}
         </p>
      </div>
   )
}

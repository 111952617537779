import { setDoc, getFirestore, doc, getDoc } from "firebase/firestore"
import {
   createUserWithEmailAndPassword,
   getAuth,
   updateProfile,
} from "firebase/auth"
import { errorResponse, okResponse } from "../components/helpers/serverResponse"
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import {
   defualtSettings,
   defualtTimezone,
} from "../components/helpers/defualtValues"
import { getSettings } from "./settingsController"

export async function getProfilePicture() {
   try {
      const user = (await getAuth()).currentUser

      const storage = getStorage()
      const url = await getDownloadURL(
         ref(storage, "profile-pictures/" + user.uid)
      )

      return url
   } catch (e) {
      return ""
   }
}

export async function updateProfilePicture(file) {
   const user = (await getAuth()).currentUser
   const storage = getStorage()
   const profilePictureRef = ref(storage, "profile-pictures/" + user.uid)
   await uploadBytes(profilePictureRef, file)

   return await getProfilePicture()
}

export async function registerUser(details) {
   var user = null
   try {
      const response = await createUserWithEmailAndPassword(
         getAuth(),
         details.email,
         details.password
      )

      user = response.user
   } catch (error) {
      if (error.code === "auth/email-already-in-use") {
         return errorResponse("Email already in use")
      }

      return errorResponse("Unable to register")
   }

   const db = getFirestore()

   const profile = {
      name: details.name,
      email: details.email,
      settings: defualtSettings,
   }

   updateProfile(user, {
      displayName: details.name,
   })

   const out = await setDoc(doc(db, "dispatchers", user.uid), profile)

   return okResponse("User registered successfully", out)
}

export function getDispatcherId() {
   try {
      return (getAuth().currentUser || {}).uid
   } catch (e) {
      return null
   }
}

export async function getDispatcherDetails(uid) {
   try {
      const ref = doc(getFirestore(), "dispatchers", uid ?? getDispatcherId())
      return (await getDoc(ref)).data()
   } catch (e) {
      return false
   }
}

export async function updateDispatcherProfile(uid, updates) {
   try {
      await setDoc(doc(getFirestore(), "dispatchers", uid), updates, {
         merge: true,
      })

      return true
   } catch (e) {
      return false
   }
}

var timezoneValues = null
export async function loadTimezones() {
   var s = await getSettings()

   timezoneValues = s
}

export function getDefaultTimezoneValues() {
   return (
      timezoneValues ?? {
         defaultTimezone: "-6:00",
      }
   )
}

import { Select } from "antd"
import React from "react"
import { getTimeZones } from "@vvo/tzdb"

const { Option } = Select
export default function TimezoneSelect(props) {
   return (
      <Select {...props} showSearch>
         {getTimeZones().map((t) => {
            return <Option value={t.rawFormat}>{t.rawFormat}</Option>
         })}
      </Select>
   )
}

//@ts-nocheck
import { EditFilled } from "@ant-design/icons"
import { Button, Input, message, Modal } from "antd"
import React, { useState } from "react"
import { updateTripNote } from "../../../../firebase/rideMangement"

interface UpdateNoteProps {
   note: string
   tripId: string
}

const { TextArea } = Input

export default function UpdateNote({ note, tripId }: UpdateNoteProps) {
   const [newNote, setNewNote] = useState(note)
   const [showModal, setShowModal] = useState(false)
   const [loading, setLoading] = useState(false)

   return (
      <>
         <Modal
            okText='Update'
            okButtonProps={{
               loading: loading,
            }}
            title='Update Note'
            onOk={async () => {
               message.info("Updating note...")
               setLoading(true)
               const response = await updateTripNote(tripId, newNote)
               if (response.isSuccess) {
                  message.success("Note updated successfully")
               } else {
                  message.error("Error updating note")
               }
               setLoading(false)
               setShowModal(false)
            }}
            onCancel={() => setShowModal(false)}
            visible={showModal}>
            <TextArea
               rows={4}
               placeholder='Enter note'
               value={newNote}
               onChange={(e) => setNewNote(e.target.value)}
            />
         </Modal>
         <EditFilled
            onClick={() => setShowModal(true)}
            className='ml-2'
            style={{
               fontSize: "12px",
               color: "black",
            }}
         />
      </>
   )
}

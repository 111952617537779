import { EditFilled } from "@ant-design/icons"
import { Button, Form, Input, message, Modal } from "antd"
import React, { useState } from "react"

export default function FeedbackForm({
   buttonText = "Feedback",
   onFeedback,
   title = "Provide Private Feedback",
   defaultFeedback = "",
   ...props
}) {
   const [show, setShow] = useState(false)
   const [form] = Form.useForm()
   const [loading, setLoading] = useState(false)
   return (
      <>
         <Modal
            cancelButtonProps={{
               disabled: loading,
            }}
            okButtonProps={{
               loading: loading,
            }}
            closable={!loading}
            okText='Submit'
            onOk={() => form.submit()}
            onCancel={() => setShow(false)}
            title={title}
            visible={show}>
            <Form
               onFinish={async (f) => {
                  setLoading(true)
                  if (onFeedback) {
                     const response = await onFeedback(f.feedback)

                     if (response) {
                        message.success("Feedback Saved")
                     } else message.error("Unknown error")
                  }
                  setLoading(false)
                  setShow(false)
               }}
               form={form}
               layout='vertical'>
               <Form.Item
                  name={"feedback"}
                  rules={[
                     {
                        min: 5,
                        required: true,
                        message: "Please input your feedback!",
                     },
                  ]}>
                  <Input.TextArea
                     defaultValue={defaultFeedback}
                     rows={5}
                     placeholder='Your feedback...'
                  />
               </Form.Item>
            </Form>
         </Modal>
         <Button {...props} onClick={() => setShow(true)} icon={<EditFilled />}>
            {buttonText}
         </Button>
      </>
   )
}

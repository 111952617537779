import dateFormat, { masks } from "dateformat"

export function dateToString(date) {
   if (!date) return "Invalid Date"
   try {
      return dateFormat(date, "dddd, mmmm dS, yyyy, h:MM TT")
   } catch (e) {
      return "Invalid Date"
   }
}

export function formatDateToString(date, format = "yyyy-mm-dd hh:MM:ss") {
   try {
      return dateFormat(date, format)
   } catch (e) {
      return "Invalid Date"
   }
}

export function getShortFormatedDate(date: string | Date) {
   try {
      return dateFormat(date, "mm/dd/yy, h:MM TT")
   } catch (e) {
      return "Invalid Date"
   }
}

export function convertToLocaleString(dd: Date) {
   try {
      return dd.toLocaleString("en-US").replace(" ", " ")
   } catch (e) {
      return ""
   }
}

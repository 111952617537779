//@ts-nocheck
import { Autocomplete } from "@react-google-maps/api"
import React, { useState } from "react"

interface PlaceSuggestInputProps {
   icon?: any
   className?: string
   onChange?: any
   placeholder?: string
   value?: string
   suffix?: any
   prefix?: any
   onLocationChange?: any
   size?: string
}

export default function PlaceSuggestInput({
   icon,
   className = "",
   onChange,
   placeholder,
   value,
   suffix,
   prefix,
   onLocationChange,
   size = "md",
}: PlaceSuggestInputProps) {
   const [autoComplete, setAutoComplete] = useState(null)
   const onLoad = (autocomplete) => {
      setAutoComplete(autocomplete)
   }

   const onPlaceChanged = () => {
      if (autoComplete !== null) {
         const l = autoComplete.getPlace()
         let loc = {
            address: l.formatted_address,
            location: {
               lat: l.geometry?.location?.lat(),
               lng: l.geometry?.location?.lng(),
            },
         }
         onLocationChange?.(loc)
         onChange?.(l.formatted_address)
      } else {
         console.log("Autocomplete is not loaded yet!")
      }
   }

   const sizeClass = size === "md" ? " " : "-" + size + " "

   return (
      <>
         <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
            <span
               className={
                  "ant-input-affix-wrapper ant-input-affix-wrapper" +
                  sizeClass +
                  className
               }>
               <span className='ant-input-prefix'>{icon ?? prefix}</span>
               <input
                  defaultValue={value}
                  placeholder={placeholder}
                  className={"ant-input ant-input" + sizeClass}
                  type='text'
               />
               <span className='ant-input-suffix'>{suffix}</span>
            </span>
         </Autocomplete>
      </>
   )
}

import { Button, Popconfirm, Space, Tag } from "antd"
import { Table } from "ant-table-extensions"
import React, { useEffect, useState } from "react"
import { SyncOutlined } from "@ant-design/icons"
import { BiSearch } from "react-icons/bi"
import ViewUpdatePassenger from "./ViewUpdatePassenger"
import {
   deletePassenger,
   getAllPassengers,
} from "../../../firebase/passengerManagement"
import Loading from "../../Loading"
import { sendErrorNotification } from "../../helpers/notification"
import Tour from "reactour"
import {
   getTourDetails,
   setTour,
   setTourDetail,
} from "../../helpers/reactTourHelper"

const steps = [
   {
      content: "Let's add a passenger",
   },
   {
      selector: "#add-passenger-button",
      content: "Click here to add new passenger",
   },
   {
      selector: ".add-passenger-form",
      content: "Add Passenger Details",
   },
   {
      selector: ".submit-add-passenger-button",
      content: "Click here to continue adding",
   },
   {
      content: "That's it! You're done!",
   },
]

export default function Passengers() {
   const [passengers, setPassengers] = useState([])
   const [loading, setLoading] = useState(false)
   const [tourEnabled, setTourEnabled] = useState(false)
   const [step, setStep] = useState(0)

   useEffect(() => {
      const loadTourDetail = async () => {
         const td = await getTourDetails()

         if (!td) return

         if (!td.passenger) {
            setTourEnabled(true)
         }

         setTour((e) => {
            const index = steps.findIndex((i) => i.selector === e)
            setStep(index + 1)
         })
      }

      loadTourDetail()
   }, [])

   const setDeletingStatus = (id, status) => {
      const newPassengers = passengers.map((passenger) => {
         if (passenger.id === id) {
            return { ...passenger, deleting: status }
         }
         return passenger
      })
      setPassengers(newPassengers)
   }

   const columns = [
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
      },
      {
         title: "Phone",
         dataIndex: "phone",
         key: "phone",
      },
      {
         title: "Company Name",
         dataIndex: "company",
         key: "company",
      },
      {
         title: "Vip",
         dataIndex: "vip",
         key: "vip",
         render: (v, r) => {
            return v ? "Yes" : "No"
         },
      },
      {
         title: "Action",
         key: "action",
         render: (text, record) => (
            <Space size={"small"}>
               <ViewUpdatePassenger
                  onUpdate={(update) => {
                     setPassengers(
                        passengers.map((p) => {
                           if (p.id === record.id) {
                              return {
                                 ...p,
                                 ...update,
                              }
                           }

                           return p
                        })
                     )
                  }}
                  passenger={record}
                  type='update'
               />
               {record.deleting ? (
                  <Tag color='red' icon={<SyncOutlined spin />}>
                     Deleting
                  </Tag>
               ) : (
                  <Popconfirm
                     title='Are you sure?'
                     onConfirm={async () => {
                        setPassengers(
                           passengers.map((p) => {
                              if (p.id === record.id) {
                                 p.deleting = true
                              }
                              return p
                           })
                        )
                        setDeletingStatus(record.id, true)
                        const response = await deletePassenger(record.id)
                        setDeletingStatus(record.id, false)

                        if (response) {
                           setPassengers(
                              passengers.filter((p) => p.id !== record.id)
                           )
                        } else sendErrorNotification("Error deleting passenger")

                        setPassengers(
                           passengers.filter((p) => p.id !== record.id)
                        )
                     }}>
                     <Button type='link' className='text-red-500'>
                        Delete
                     </Button>
                  </Popconfirm>
               )}
            </Space>
         ),
      },
   ]

   useEffect(() => {
      const loadPassengers = async () => {
         setLoading(true)
         const passengers = await getAllPassengers()
         setPassengers(passengers)
         setLoading(false)
      }
      loadPassengers()
   }, [])

   return (
      <>
         <Tour
            disableFocusLock={true}
            getCurrentStep={setStep}
            goToStep={step}
            steps={steps}
            isOpen={tourEnabled}
            finish
            lastStepNextButton={<Button>Finish</Button>}
            onRequestClose={(a) => {
               setTourDetail("passenger", true)
               setTourEnabled(false)
            }}
         />
         <div className='flex w-full flex-col h-full'>
            <div className='flex justify-between items-center mb-8 p-6 rounded-lg border bg-gradient-to-r from-blue-50 to-blue-200'>
               <label className='text-xl font-semibold'>Passengers</label>
               <ViewUpdatePassenger
                  onAdd={(p) => {
                     setPassengers([...passengers, p])
                  }}
                  type='add'
               />
            </div>
            <div className='flex flex-1 flex-col'>
               {loading ? (
                  <Loading />
               ) : (
                  <Table
                     searchableProps={{
                        inputProps: {
                           size: "large",
                           placeholder: "Search Passengers",
                           prefix: <BiSearch className='text-xl mr-1' />,
                        },
                     }}
                     searchable={true}
                     columns={columns}
                     dataSource={passengers}
                  />
               )}
            </div>
         </div>
      </>
   )
}

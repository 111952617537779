import React, { useState } from "react";
import { Form, Input, Button, Alert } from "antd";
import { MailOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
import { Col, Divider, Image, Row } from "antd";
import { useHistory } from "react-router";

import backgroundCarImage from "../../assets/carbg.png";
import LRlogoColor from "../../assets/lrlogocolor.svg";
import { registerUser } from "../../firebase/profileManagement";

export default function Register() {
  const [error, setError] = useState("");
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    if (values.password !== values.confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    setError("");
    setIsLoading(true);

    const response = await registerUser(values);

    if (!response.isSuccess) {
      setError(response.message);
    } else {
      history.push("/login");
    }
    setIsLoading(false);
  };
  return (
    <Row
      className="h-screen flex flex-col md:flex-row items-center"
      style={{
        background: `url(${backgroundCarImage})`,
        backgroundSize: "cover",
      }}
    >
      <Col
        xs={24}
        md={14}
        className="flex flex-col justify-center items-center"
      >
        <Image src={LRlogoColor} preview={false} className="w-96" />
        <h2 className="mt-5 md:mt-8 text-3xl md:text-4xl text-white font-light">
          Book Your Ride
        </h2>
      </Col>
      <Col xs={24} md={10} className="flex justify-center items-center">
        <div className="flex flex-col bg-white p-8 w-96 rounded-2xl">
          <h1 className="text-2xl font-bold mb-8">
            Login to LR Car <br /> Services
          </h1>
          <Form
            autocomplete="off"
            name="normal_login"
            className="login-form mt-8"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your full name",
                  min: 4,
                  max: 20,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="text-xl mr-2 mt-1" />}
                placeholder="Full Name"
                className="border-1 border-solid rounded-md h-12"
                style={{ borderColor: "#272A30" }}
              />
            </Form.Item>

            <Form.Item
              autocomplete="off"
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your email",
                },
              ]}
            >
              <Input
                prefix={<MailOutlined className="text-xl mr-2 mt-1" />}
                placeholder="Email"
                className="border-1 border-solid rounded-md h-12"
                style={{ borderColor: "#272A30" }}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input password",
                  min: 6,
                  max: 20,
                },
              ]}
            >
              <Input.Password
                autocomplete="new-password"
                prefix={<LockOutlined className="text-xl mr-2 mt-1" />}
                placeholder="Password"
                className="border-1 border-solid rounded-md h-12"
                style={{ borderColor: "#272A30" }}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: "Please input confirm password",
                  min: 6,
                  max: 20,
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="text-xl mr-2 mt-1" />}
                type="password"
                placeholder="Confirm Password"
                className="border-1 border-solid rounded-md h-12"
                style={{ borderColor: "#272A30" }}
              />
            </Form.Item>
            {error !== null && error !== "" ? (
              <Alert message={error} type="error" showIcon />
            ) : (
              <></>
            )}

            <Form.Item>
              <Button
                loading={isLoading}
                htmlType="submit"
                className="w-full text-black h-11 font-semibold mt-5"
                style={{
                  backgroundColor: "#FEC42E",
                  borderColor: "#FEC42E",
                }}
              >
                REGISTER
              </Button>
            </Form.Item>
          </Form>
          <Divider className="mt-0" />
          <Button
            className="w-full border-1 border-solid rounded h-11 font-semibold"
            onClick={() => history.push("/login")}
            style={{ borderColor: "#505050" }}
          >
            LOGIN
          </Button>
        </div>
      </Col>
    </Row>
  );
}

import { CarFilled } from "@ant-design/icons"
import { Avatar, Button } from "antd"
import React, { useEffect, useState } from "react"
import { GoPrimitiveDot } from "react-icons/go"
import { ImPhone } from "react-icons/im"
import { MdMail } from "react-icons/md"
import { getDriverProfilePicture } from "../../../firebase/driverManagement"
import DriversOnMap from "./DriversOnMap"
import { useHistory } from "react-router-dom"
import DriverLevel from "./levels/DriverLevel"
export default function Driver({
   driver,
   showMap = true,
   size = "medium",
   className = "",
   showMessage,
   showSelect,
   onDriverSelect,
   pickupDistance,
   pickupTime,
   mapDirections,
   customMapItems = [],
}) {
   const [profileUrl, setProfileUrl] = useState("")
   const history = useHistory()

   useEffect(() => {
      const loadProfilePictureUrl = async () => {
         if (!driver?.id) return
         const url = await getDriverProfilePicture(driver.id)
         setProfileUrl(url)
      }
      loadProfilePictureUrl()
   }, [driver])

   return (
      <div
         className={
            "flex-1 flex flex-col bg-gradient-to-r from-blue-100 to-blue-200 rounded-lg " +
            className
         }>
         {pickupDistance && pickupTime && (
            <div className='pr-2 pb-1 pl-2 pt-1 bg-blue-500 flex flex-row justify-between rounded-t-md'>
               <p className='text-white text-sm m-0'>{pickupDistance}</p>
               <p className='text-white text-sm m-0'>{pickupTime}</p>
            </div>
         )}
         <div className='pb-5 p-5 pr-5 pl-5 flex flex-col flex-1'>
            <div className='flex flex-row justify-between mb-6'>
               <div className='flex items-center'>
                  <div className='relative'>
                     <GoPrimitiveDot
                        className={
                           "absolute z-10 left-7 top-5 text-xl " +
                           (driver.online ? "text-green-400" : "text-red-400")
                        }
                     />

                     <Avatar key={driver.id} src={profileUrl} size='large'>
                        <span className='text-lg'>
                           {driver.fullname.charAt(0).toUpperCase()}
                        </span>
                     </Avatar>
                  </div>
                  <label
                     className={`text-${
                        size === "medium" ? "lg" : "md"
                     } font-medium flex-1 ml-3`}>
                     {driver.fullname}
                  </label>
               </div>
               <div className='flex items-center'>
                  {showMessage && (
                     <Button
                        className='mr-1'
                        onClick={() => {
                           history.push("/dashboard/messages/" + driver.id)
                        }}
                        type='primary'
                        size='small'>
                        Message
                     </Button>
                  )}
                  {showSelect && (
                     <Button
                        className='mr-1'
                        onClick={() => {
                           onDriverSelect?.(driver)
                        }}
                        type='primary'
                        size='small'>
                        Select
                     </Button>
                  )}
                  {showMap ? (
                     <DriversOnMap
                        directions={mapDirections}
                        key={driver.id + "-on-map"}
                        profileUrl={profileUrl}
                        customItems={customMapItems}
                        drivers={[
                           {
                              ...driver,
                              image: profileUrl,
                           },
                        ]}
                     />
                  ) : (
                     <></>
                  )}
               </div>
            </div>
            <div className='flex flex-row justify-between'>
               <div className='flex flex-col mr-1 justify-between'>
                  <div className='mb-1'>
                     <DriverLevel level={driver.verification.level} />
                  </div>
                  <div className='flex items-center'>
                     <ImPhone
                        className={`mr-2 text-${
                           size === "medium" ? "2xl" : "sm"
                        } text-gray-700`}
                     />
                     <span
                        className={`text-${size === "medium" ? "sm" : "xs"}`}>
                        {driver.phone ? driver.phone : "Not-Specifed"}
                     </span>
                  </div>
               </div>

               <div className='flex flex-col mr-1 justify-between'>
                  <div className='flex mb-1'>
                     <CarFilled
                        className={`mr-2 text-${
                           size === "medium" ? "2xl" : "sm"
                        } text-gray-700`}
                     />
                     <span
                        className={`text-${size === "medium" ? "sm" : "xs"}`}>
                        {driver.vehicle.make
                           ? driver.vehicle.make
                           : "Not-Specifed"}
                     </span>
                  </div>
                  <div className='flex items-center'>
                     <MdMail
                        className={`mr-2 text-${
                           size === "medium" ? "2xl" : "sm"
                        } text-gray-700`}
                     />
                     <span
                        className={`text-${size === "medium" ? "sm" : "xs"}`}>
                        {driver.email ? driver.email : "Not-Specifed"}
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

import React from "react"
import { BsTriangleFill } from "react-icons/bs"

export const getLevelIcon = (level, textProps = {}, mainProps = {}) => {
   if (level === 1)
      return (
         <div className='flex flex-row items-center' {...mainProps}>
            <BsTriangleFill className='text-gray-400 mr-2 text-2xl' />
            <span className='text-small' {...textProps}>
               Silver
            </span>
         </div>
      )
   if (level === 2)
      return (
         <div className='flex flex-row items-center' {...mainProps}>
            <BsTriangleFill className='text-yellow-500 text-2xl mr-2' />
            <span className='text-base' {...textProps}>
               Gold
            </span>
         </div>
      )
   if (level === 3)
      return (
         <div className='flex flex-row items-center' {...mainProps}>
            <BsTriangleFill className='text-gray-600  mr-2 text-2xl' />
            <span className='text-base' {...textProps}>
               Platinum
            </span>
         </div>
      )

   return <BsTriangleFill />
}

import { getAuth } from "firebase/auth"
import {
   getDispatcherDetails,
   getDispatcherId,
   updateDispatcherProfile,
} from "../../firebase/profileManagement"

export function setTour(tour) {
   reactTour = tour
}

var reactTour = null

export function onReactTourActionPerformed(key) {
   if (reactTour != null) {
      reactTour(key)
   }
}

var data = {
   dashboard: false,
   passenger: false,
   tracking: false,
}

export async function setTourDetail(key, val) {
   const dispatcher = await getDispatcherDetails(getDispatcherId())
   const ds = dispatcher.settings
   var ns = {}
   if (ds) {
      if (ds.tour) {
         ns = {
            ...ds,
            tour: {
               ...data,
               ...ds.tour,
               [key]: val,
            },
         }
      } else {
         ns = {
            ...ds,
            tour: {
               ...data,
               [key]: val,
            },
         }
      }
   } else {
      ns = {
         tour: {
            ...data,
            [key]: val,
         },
      }
   }

   await updateDispatcherProfile(getDispatcherId(), {
      settings: ns,
   })
}

export async function getTourDetails() {
   try {
      const dispatcher = await getDispatcherDetails(getDispatcherId())
      if (dispatcher?.settings?.tour) return dispatcher.settings.tour

      return data
   } catch (e) {
      return null
   }
}

//@ts-nocheck
import React, { useEffect, useState } from "react"
import {
   Form,
   Input,
   Button,
   Row,
   Col,
   Switch,
   Divider,
   DatePicker,
   Tooltip,
   Descriptions,
   message,
} from "antd"
import {
   PlusOutlined,
   CloseOutlined,
   InfoCircleTwoTone,
} from "@ant-design/icons"
import { FaMapMarkerAlt, FaExclamationCircle } from "react-icons/fa"
import moment from "moment"
import { MdGpsFixed } from "react-icons/md"
import { startNewRide } from "../../../firebase/rideMangement"
import { serverTimestamp } from "firebase/firestore"
import {
   getDefaultTimezoneValues,
   getDispatcherDetails,
} from "../../../firebase/profileManagement"
import { dispatcherStatus } from "../../helpers/status"
import { getAuth, signOut } from "firebase/auth"
import SelectPassenger from "./rideform/SelectPassenger"
import { getLevelIcon } from "../../helpers/level"
import { getTimeZones } from "@vvo/tzdb"
import momentTZ from "moment-timezone"
import { Timestamp } from "firebase/firestore"
import PlaceSuggestInput from "../maps/PlaceSuggestInput"
import { GiStopSign } from "react-icons/gi"
import SelectDriver from "./rideform/SelectDriver"
import {
   Trip,
   TripStatus,
   Destination,
   DestinationType,
   DestinationStatus,
   TripType,
} from "../../../types/trip"
import { convertToLocaleString } from "../../helpers/dateHelper"

const { TextArea } = Input

export default function StartRideForm({ onPickupLocationChange }) {
   const [form] = Form.useForm()
   const [selectedPassenger, setSelectedPassenger] = useState(null)

   const [showError, setShowError] = useState(false)
   const [loading, setLoading] = useState(false)
   const [showDriverError, setShowDriverError] = useState(false)

   const [selectedDriver, setSelectedDriver] = useState(null)

   const calcTime = (offset, date = null) => {
      console.log("offset", offset)
      // create Date object for current location
      var d = date ?? new Date()

      // convert to msec
      // subtract local time zone offset
      // get UTC time in msec
      var utc = d.getTime() + d.getTimezoneOffset() * 60000

      // create new Date object for different city
      // using supplied offset
      var nd = new Date(utc + 3600000 * offset)

      return nd
   }

   const initializeForm = () => {
      form.setFieldsValue({
         name: "",
         phone: "",
         pickupLocation: "",
         dropOffLocation: "",
         note: "",
         email: "",
         vip: true,
         pickupDate: moment(
            calcTime(getDefaultTimezoneValues().defaultTimezone.split(":")[0])
         ),
         stopLocations: [],
         timezone: getTimeZones().filter((t) =>
            t.name.toLowerCase().includes(momentTZ.tz.guess().toLowerCase())
         )[0]?.rawFormat,
      })
   }

   useEffect(() => {
      initializeForm()
   }, [])

   const onFinish = async (values) => {
      if (selectedPassenger === null) return
      if (selectedDriver === null) return

      setShowDriverError(false)
      setShowError(false)

      var destinations: Destination[] = []

      destinations.push({
         address: values.pickupLocation,
         arriveTime: Timestamp.fromMillis(values.pickupDate._d / 1),
         arrived: false,
         type: DestinationType.pickup,
         status: DestinationStatus.notStarted,
         formattedArriveTime: convertToLocaleString(values.pickupDate._d),
      })

      values.stopLocations.forEach((sl) => {
         destinations.push({
            arrived: false,
            address: sl.location,
            arriveTime: Timestamp.fromMillis(sl.date._d / 1),
            formattedArriveTime: convertToLocaleString(sl.date._d),
            partialDropoff: sl.partialDropOff ? true : false,
            type: DestinationType.stop,
            status: DestinationStatus.notStarted,
         })
      })

      destinations.push({
         address: values.dropOffLocation,
         arriveTime: null,
         arrived: false,
         type: DestinationType.dropoff,
         status: DestinationStatus.notStarted,
      })

      const newTrip: Trip = {
         addedAt: serverTimestamp(),
         passenger: {
            name: selectedPassenger.name,
            email: selectedPassenger.email,
            phone: selectedPassenger.phone,
            id: selectedPassenger.id,
            vip: selectedPassenger.vip,
         },
         currentPosition: -1,
         driverId: selectedDriver.id,
         lastUpdated: serverTimestamp(),
         status: TripStatus.accepted,
         route: destinations,
         waiting: false,
         note: values.note,
         waitingTimes: [],
         show: false,
         version: 1,
         distanceCovered: 0,
         totalTimeInSeconds: 0,
         waitingTimeInSeconds: 0,
         dispatcherId: getAuth().currentUser.uid,
         type: TripType.pending,
      }

      message.info("Submiting ride information")
      setLoading(true)
      try {
         const d = await getDispatcherDetails()

         if (
            d &&
            d.verification &&
            d.verification.status !== dispatcherStatus.approved
         ) {
            signOut(getAuth())
         }
      } catch (e) {}

      const result = await startNewRide(newTrip)
      setLoading(false)

      if (result) {
         message.success("Ride information submitted, waiting for reply.")
      } else message.error("Error Submiting Ride Information")
   }

   return (
      <div
         className=' w-full p-8 rounded-xl borde overflow-scroll'
         style={{ background: "#F7F8FC" }}>
         <div className='flex flex-row'>
            <h1 className='text-xl font-semibold mb-8'>Request a Ride</h1>
            <div
               style={{ height: "32px" }}
               className='pl-2 flex flex-1 flex-row items-top justify-end'>
               <SelectPassenger
                  onSelect={(p) => {
                     setSelectedPassenger(p)
                  }}
                  className='mr-2'
               />
               <SelectDriver
                  onSelect={(d, pl) => {
                     setSelectedDriver(d)
                  }}
               />
            </div>
         </div>
         <Form
            layout='vertical'
            onSubmitCapture={() => {
               if (selectedPassenger === null) {
                  setShowError(true)
               }

               if (selectedDriver === null) {
                  setShowDriverError(true)
               }

               return false
            }}
            name='normal_Add_Parcel'
            form={form}
            onFinish={onFinish}>
            <Row className='mb-6'>
               <div
                  className={
                     "w-full rounded-md border bg-white " +
                     (showError
                        ? "border-red-400 text-red-400"
                        : "border-gray-300")
                  }>
                  {selectedPassenger === null ? (
                     <div className='p-5'>
                        <InfoCircleTwoTone
                           className={
                              "mr-2 text-red-400 " +
                              (showError ? "text-red-400" : "")
                           }
                        />
                        Select passenger to display information
                     </div>
                  ) : (
                     <Descriptions
                        className='w-full '
                        bordered
                        layout='vertical'
                        column={{
                           sm: 1,
                           md: 2,
                           lg: 4,
                        }}
                        size='small'>
                        <Descriptions.Item label="Passenger's Name">
                           {selectedPassenger.name}
                        </Descriptions.Item>
                        <Descriptions.Item label='Email'>
                           {selectedPassenger.email}
                        </Descriptions.Item>
                        <Descriptions.Item label='Phone'>
                           {selectedPassenger.phone}
                        </Descriptions.Item>
                        <Descriptions.Item label='Vip'>
                           {selectedPassenger.vip ? "Yes" : "No"}
                        </Descriptions.Item>
                     </Descriptions>
                  )}
               </div>
            </Row>
            <Row className='mb-6'>
               <div
                  className={
                     "w-full rounded-md border bg-white " +
                     (showDriverError
                        ? "border-red-400 text-red-400"
                        : "border-gray-300")
                  }>
                  {selectedDriver === null ? (
                     <div className='p-5'>
                        <InfoCircleTwoTone
                           className={
                              "mr-2 text-red-400 " +
                              (showDriverError ? "text-red-400" : "")
                           }
                        />
                        Select Driver to display information
                     </div>
                  ) : (
                     <Descriptions
                        className='w-full '
                        bordered
                        layout='vertical'
                        column={{
                           sm: 1,
                           md: 2,
                           lg: 4,
                        }}
                        size='small'>
                        <Descriptions.Item label="Driver's Name">
                           {selectedDriver.fullname}
                        </Descriptions.Item>
                        <Descriptions.Item label='Email'>
                           {selectedDriver.email}
                        </Descriptions.Item>
                        <Descriptions.Item label='Phone'>
                           {selectedDriver.phone}
                        </Descriptions.Item>
                        <Descriptions.Item label='Level'>
                           {getLevelIcon(selectedDriver.verification.level)}
                        </Descriptions.Item>
                     </Descriptions>
                  )}
               </div>
            </Row>
            <Row>
               <Col sm={24} md={11}>
                  <Form.Item
                     label='Pickup Location'
                     name='pickupLocation'
                     rules={[
                        {
                           required: true,
                           message: "Please input pick up location",
                        },
                     ]}>
                     <PlaceSuggestInput
                        onLocationChange={(l) => {
                           onPickupLocationChange(l)
                        }}
                        className='rounded-md py-2 start-form-pickup-location'
                        size='large'
                        placeholder='Pick Up Location'
                        icon={
                           <MdGpsFixed
                              className='ml-1 mr-2 text-xl'
                              style={{ color: "#505050" }}
                           />
                        }
                     />
                  </Form.Item>

                  <Form.Item
                     label='Drop Off Location'
                     name='dropOffLocation'
                     rules={[
                        {
                           required: true,
                           message: "Please input drop off location",
                        },
                     ]}>
                     <PlaceSuggestInput
                        className='rounded-md py-2 start-form-dropoff-location'
                        size='large'
                        icon={
                           <FaMapMarkerAlt
                              className='ml-1 mr-2 text-lg'
                              style={{ color: "#505050" }}
                           />
                        }
                        placeholder='Drop Off Location'
                     />
                  </Form.Item>
                  <Form.Item
                     label='Note'
                     name='note'
                     rules={[{ required: false }]}>
                     <TextArea
                        className='rounded-md'
                        autoSize={{ minRows: 4, maxRows: 6 }}
                        size='large'
                        placeholder='Add Note'
                     />
                  </Form.Item>
                  <Divider className='bg-gray-300' />

                  <Row>
                     <Col span={24}>
                        <Form.Item
                           label='Pickup Date & Time'
                           rules={[
                              {
                                 required: true,
                              },
                           ]}
                           className='Pickup Date'
                           name='pickupDate'>
                           <DatePicker
                              showTime
                              placeholder='Pickup Date & Time'
                              className='w-full start-form-pickup-date'
                              size='large'
                              format='MM-DD-YYYY HH:mm'
                           />
                        </Form.Item>
                     </Col>
                  </Row>

                  <div className='flex flex-col items-start'>
                     <Form.Item className='mb-0'>
                        <Button
                           loading={loading}
                           size='large'
                           id='start-form-schedule-ride'
                           type='primary'
                           htmlType='submit'
                           className='login-form-button rounded-md w-full'>
                           Schedule Ride
                        </Button>
                     </Form.Item>
                     <Button
                        size='large'
                        type='link'
                        className=''
                        onClick={() => {
                           setSelectedPassenger(null)
                           initializeForm()
                        }}>
                        Clear
                     </Button>
                  </div>
               </Col>

               <Col sm={0} md={2} className='flex items-center justify-center'>
                  <Divider
                     className='mt-4 h-full bg-gray-300'
                     type='vertical'
                  />
               </Col>

               <Col sm={24} md={11}>
                  <label>Stop Locations</label>
                  <Form.List name='stopLocations'>
                     {(fields, { add, remove }) => (
                        <>
                           <Form.Item>
                              <Button
                                 block
                                 size='large'
                                 type='dashed'
                                 onClick={() => add()}
                                 icon={<PlusOutlined />}
                                 className='mt-2 flex items-center justify-center bg-transparent'>
                                 Add Stop Location
                              </Button>
                           </Form.Item>
                           <div
                              className='overflow-y-auto mb-5'
                              style={{
                                 maxHeight: "500px",
                              }}>
                              {fields.map(({ key, name, ...restField }) => (
                                 <div
                                    key={key}
                                    className='bg-blue-800 bg-opacity-5 flex flex-col p-5 rounded-md mb-5'>
                                    <div className='flex justify-between'>
                                       <div className='flex items-center'>
                                          <Form.Item
                                             {...restField}
                                             name={[name, "partialDropOff"]}
                                             valuePropName='checked'>
                                             <Switch size={"default"} />
                                          </Form.Item>
                                          <p className='flex flex-row justify-center items-center m-0 ml-3 pb-6 text-base font-medium text-gray-600'>
                                             <span>Partial Dropoff</span>
                                             <Tooltip title='Drivers have to wait for some time and then get back to pickup the passenger'>
                                                <FaExclamationCircle className='ml-2' />
                                             </Tooltip>
                                          </p>
                                       </div>

                                       <div className='flex items-center pb-5'>
                                          <Button
                                             type='text'
                                             onClick={() => remove(name)}
                                             icon={<CloseOutlined />}
                                          />
                                       </div>
                                    </div>

                                    <Form.Item
                                       {...restField}
                                       label='Select Date & Time'
                                       rules={[
                                          {
                                             required: true,
                                             message: "Select Time",
                                          },
                                       ]}
                                       name={[name, "date"]}>
                                       <DatePicker
                                          placeholder='Date & Time'
                                          showTime
                                          className='w-full -mb-3 rounded-md'
                                          size='large'
                                          format='MM-DD-YYYY HH:mm'
                                       />
                                    </Form.Item>

                                    <Form.Item
                                       {...restField}
                                       label='Stop Location'
                                       rules={[
                                          {
                                             required: true,
                                             message:
                                                "Please input stop location",
                                          },
                                       ]}
                                       name={[name, "location"]}>
                                       <PlaceSuggestInput
                                          className='rounded-md'
                                          placeholder='Stop Location'
                                          size='large'
                                          suffix={
                                             <GiStopSign
                                                className='text-base'
                                                style={{ color: "#505050" }}
                                             />
                                          }
                                       />
                                    </Form.Item>
                                 </div>
                              ))}
                           </div>
                        </>
                     )}
                  </Form.List>
               </Col>
            </Row>
         </Form>
      </div>
   )
}

import React, { useEffect, useState } from "react"
import { collection, getFirestore, query, where } from "firebase/firestore"
import DriversOnMap from "./DriversOnMap"
import Loading from "../../Loading"
import { HiOutlineEmojiSad } from "react-icons/hi"
import { Input, Radio } from "antd"
import Driver from "./Driver"
import { driverStatus } from "../../helpers/status"
import { useCollectionData } from "react-firebase-hooks/firestore"
import {
   calculateDirectionRoute,
   calculateDistanceBetweenTwoPoints,
} from "../../helpers/locationHelper"
import { Marker } from "react-google-maps"
const randomColor = require("randomcolor")

export default function ActiveDrivers({ pickupLocation }) {
   const [drivers, setDrivers] = useState([])
   const [filteredDrivers, setFilteredDrivers] = useState([])
   const [filters, setFilters] = useState({
      online: true,
      level: 0,
      name: "",
   })

   const [distances, setDistances] = useState([])
   const [directions, setDirections] = useState([])

   useEffect(() => {
      if (pickupLocation && pickupLocation !== "") {
         const distances = filteredDrivers
            .map((d) => {
               if (!d.location) return null
               return {
                  ...d,
                  distance: calculateDistanceBetweenTwoPoints(
                     pickupLocation.location.lat,
                     pickupLocation.location.lng,
                     d.location.lat,
                     d.location.long
                  ),
               }
            })
            .filter((d) => d !== null)

         //top 10 distances

         const top10 = distances
            .sort((a, b) => a.distance - b.distance)
            .slice(0, 10)

         //calculate route for top 10 drivers

         const routes = top10.map(async (d) => {
            try {
               const results = await calculateDirectionRoute(
                  pickupLocation.location,
                  {
                     lat: d.location.lat,
                     lng: d.location.long,
                  },
                  true
               )
               return {
                  route: results,
                  driverId: d.id,
               }
            } catch (e) {
               return null
            }
         })

         Promise.all(routes).then((r) => {
            r = r.filter((r) => r !== null)
            const colors = randomColor({
               count: 10,
               hue: "blue",
               luminosity: "dark",
            })
            setDirections(
               r.map((r, i) => ({
                  ...r,
                  color: colors[i],
               }))
            )

            setDistances(
               r.map((dir, i) => {
                  return {
                     distance: dir.route.routes?.[0]?.legs[0]?.distance?.text,
                     time: dir.route.routes?.[0]?.legs[0]?.duration?.text,
                     driverId: dir.driverId,
                     distanceValue:
                        dir.route.routes?.[0]?.legs[0]?.distance?.value,
                     timeValue: dir.route.routes?.[0]?.legs[0]?.duration?.value,
                     directions: [dir],
                  }
               })
            )
         })
      }
   }, [pickupLocation])

   const [values, loading, error, snapshot] = useCollectionData(
      query(
         collection(getFirestore(), "drivers"),
         where("online", "==", true),
         where("verification.status", "==", driverStatus.approved)
      )
   )

   useEffect(() => {
      if (!snapshot) return
      setDrivers(
         snapshot.docs.map((d) => {
            return {
               id: d.id,
               ...d.data(),
            }
         })
      )
   }, [snapshot])

   useEffect(() => {
      var fd = drivers
         .filter((d) => {
            if (filters.online && !d.online) return false

            const r = d.fullname
               .toLowerCase()
               .includes(filters.name.toLowerCase())

            if (!r) return false

            if (filters.level === 0) return true

            return filters.level === d.verification.level
         })
         .map((d) => {
            var distance = distances.find(
               (distance) => distance.driverId === d.id
            )

            if (!distance) {
               distance = {
                  distance: null,
                  time: null,
                  distanceValue: Number.POSITIVE_INFINITY,
                  timeValue: Number.POSITIVE_INFINITY,
               }
            }

            return {
               ...d,
               distance,
            }
         })
         .sort((a, b) => a.distance.distanceValue - b.distance.distanceValue)

      setFilteredDrivers(fd)
   }, [drivers, filters, distances])

   // console.log(drivers.filter((d) => d.fullname === "Usama Tariq")[0]?.location)
   return (
      <div className='flex flex-col h-full max-h-full flex-1'>
         <div className='flex flex-row mb-2'>
            <label className='flex-1 text-lg font-medium'>Active Drivers</label>
            <DriversOnMap
               key={"all-driver"}
               drivers={drivers}
               buttonText='View All'
            />
         </div>
         {loading ? (
            <div className='flex flex-1'>
               <Loading size='text-3xl' />
            </div>
         ) : drivers.length === 0 ? (
            <>
               <div className='flex flex-1 flex-col items-center justify-center'>
                  <HiOutlineEmojiSad className='text-5xl font-thin opacity-50' />
                  <label className='opacity-50'>No Driver Online</label>
               </div>
            </>
         ) : (
            <div className='flex flex-col max-h-full'>
               <div className='mb-2'>
                  <Input
                     value={filters.name}
                     onChange={(e) =>
                        setFilters({
                           ...filters,
                           name: e.target.value,
                        })
                     }
                     className='w-100'
                     placeholder='Search Driver...'
                  />
               </div>
               <div className='mb-2'>
                  <Radio.Group
                     onChange={(e) => {
                        setFilters({
                           ...filters,
                           level: parseInt(e.target.value),
                        })
                     }}
                     defaultValue={filters.level + ""}
                     size='medium'>
                     <Radio.Button value='0'>All</Radio.Button>
                     <Radio.Button value='3'>Platinum</Radio.Button>
                     <Radio.Button value='2'>Gold</Radio.Button>
                     <Radio.Button value='1'>Silver</Radio.Button>
                  </Radio.Group>
               </div>
               <div className='flex flex-col gap-5 mt-5 max-h-full overflow-scroll pb-4'>
                  {filteredDrivers.map((d, i) => {
                     return (
                        <Driver
                           pickupDistance={d.distance.distance}
                           pickupTime={d.distance.time}
                           driver={d}
                           key={"d-" + i}
                           // mapDirections={d.distance.directions ?? []}
                           // customMapItems={
                           //    pickupLocation
                           //       ? [
                           //            <Marker
                           //               title='Pickup Location'
                           //               position={pickupLocation.location}
                           //            />,
                           //         ]
                           //       : []
                           // }
                        />
                     )
                  })}
               </div>
            </div>
         )}
      </div>
   )
}
